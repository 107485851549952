import React from 'react';

type Props = {
  size?: string;
  thickness?: string;
  background?: string;
  spinColor?: string;
  text?: string;
  textSize?: string;
  textColor?: string;
  speed?: string;
};

const CustomSpinner = ({
  size,
  thickness,
  background,
  spinColor,
  text,
  textSize,
  textColor,
  speed
}: Props) => {
  return (
    <div className='custom-spinner'>
      <div
        className='custom-spinner__spinner'
        style={{
          height: size || '3rem',
          width: size || '3rem',
          border: `${thickness || '0.8rem'} solid ${background || '#dcdcdc'} `,
          borderTopColor: `#43d882`,

          background: 'transparent',
          animation: `spin ${speed || '1.5s'} linear infinite`
        }}
      ></div>
      <span style={{ fontSize: textSize, color: textColor }}>{text}</span>
    </div>
  );
};

export default CustomSpinner;
