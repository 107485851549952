export enum ShipmentStatusEnum {
  CONFIRMED = 'Confirmed',
  CANCELLED = 'Cancelled',
  DELIVERED = 'Delivered',
  AWAITING_PICK_UP = 'Awaiting Pick Up',
  IN_TRANSIT = 'In Transit',
  PICK_UP_CANCELLED = 'Pickup Cancelled',
  PICK_UP_DISPATCHED = 'Pickup Dispatched',
  SUCCESSFULLY_PICKED = 'Successfully Picked',
  PICK_UP_CANCELLED_BY_COURIER = 'Pickup Cancelled By Courier',
  PICK_UP_CANCELLED_BY_MERCHANT = 'Pickup Cancelled By Merchant',
  PICK_UP_CANCELLED_INTERNALLY = 'Pickup Cancelled Internally',
  PICK_UP_RESCHEDULED_BY_MERCHANT = 'Pickup Rescheduled By Merchant',
  DELIVERY_FAILED = 'Delivery Failed',
  AWAITING_DROP_OFF = 'Awaiting Drop Off',
  DELIVERY_IN_PROGRESS = 'Delivery In Progress',
  ASSIGNED_FOR_DELIVERY = 'Assigned For Delivery',
  PENDING_CONFIRMATION = 'Pending Confirmation',
  CLARIFICATION_NEEDED = 'Clarification Needed',
  PAYMENT_PENDING = 'PaymentPending'
}
