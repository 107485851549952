const base64ToArrayBuffer = (data: string) => {
  const bString = window.atob(data);
  const bLength = bString.length;
  const bytes = new Uint8Array(bLength);
  for (var i = 0; i < bLength; i++) {
    var ascii = bString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

const base64toPDF = (data: string, fileName: string) => {
  const bufferArray = base64ToArrayBuffer(data);
  const blobStore = new Blob([bufferArray], { type: 'application/pdf' });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blobStore);
    return;
  }
  const info = window.URL.createObjectURL(blobStore);
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = info;
  link.download = `${fileName}.pdf`;
  link.click();
  window.URL.revokeObjectURL(info);
  link.remove();
};

export { base64toPDF, base64ToArrayBuffer };
