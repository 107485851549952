import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiTabs: {
      root: {
        color: 'black'
      },
      indicator: {
        backgroundColor: '#003895'
      }
    },
    MuiListItemText: {
      root: {
        padding: '0 16px',
        marginTop: '3px',
        marginBottom: '3px'
      }
    },
    MuiTypography: {
      root: {
        color: 'black'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 'inherit',
        marginRight: '16px'
      }
    }
  },
  props: {
    MuiTypography: {
      variant: 'body2'
    }
  },
  palette: {
    primary: {
      light: '#f7cb54',
      main: '#003895',
      dark: '#042F95'
    },
    secondary: {
      main: '#f7cb54'
    }
  }
});
export default theme;
