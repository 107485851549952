import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import { loginState, saveShipmentDetailsState } from 'recoil-data/atoms';
import Client from 'services/client';
import { UserTypeEnum } from 'enums';
import { useAuthToken } from 'helpers';
import useClearLocalStorage from 'helpers/clear-storage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(0)
    },
    title: {
      flexGrow: 1,
      cursor: 'pointer'
    },
    appBar: {
      backgroundColor: '#fff'
    },
    image: {
      verticalAlign: 'bottom',
      height: '30px'
    },
    name: {
      marginRight: theme.spacing(1)
    }
  })
);

const Navbar = () => {
  const client = Client();
  const [, , eraseToken] = useAuthToken() as any;
  const [, setLoggedIn] = useRecoilState(loginState);
  const history = useHistory();
  const classes = useStyles();
  const clearStorage = useClearLocalStorage();

  const resetShipmentData = useResetRecoilState(saveShipmentDetailsState);

  const userType = localStorage.getItem('type');
  const userName = localStorage.getItem('fullName');

  const logOut = () => {
    setLoggedIn(false);
    eraseToken();
    localStorage.clear();
    clearStorage();
    client.resetStore();
    history.push('/login');
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position='static'>
        <Toolbar>
          <Typography
            className={classes.title}
            onClick={() => {
              resetShipmentData();
              history.push('/dashboard');
            }}
          >
            <img
              className={classes.image}
              src='/static/images/svg/topship.svg'
              alt='topship'
            />
          </Typography>

          <Typography className={classes.name} color='primary'>
            {userName}
            {userType === UserTypeEnum.SUPERADMIN
              ? ' (Super Administrator)'
              : ' (Administrator)'}
          </Typography>

          <IconButton
            edge='end'
            className={classes.menuButton}
            color='primary'
            aria-label='menu'
            onClick={logOut}
          >
            <PowerSettingsNewIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
