import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { toCurrency } from 'helpers';
import { BusyOverlay, DialogShell, SubmitButton } from 'components';
import { usePayFromWalletMutation } from 'operations/mutations';
import { multipleShipmentResultsState } from 'recoil-data/atoms';
import { useRecoilState } from 'recoil';
import { useGetUserInformationQuery } from 'operations/queries';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';
import { toFixed } from 'helpers/to-fixed';

type Props = {
  customerName: string;
  handleClose: () => void;
  open: boolean;
  walletBalance: number;
  walletId: string;
  userId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  title: {
    fontSize: '14px',
    fontWeight: 700
  },
  form: {
    padding: '0px 10px 10px 10px'
  },
  formContent: {
    marginTop: theme.spacing(4)
  },
  formContent2: {
    marginTop: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(6)
  },
  total: {
    color: '#ff0000'
  },
  success: {
    marginTop: '20px',
    marginBottom: '60px',
    textAlign: 'center'
  },
  message: {
    fontWeight: 500
  },
  debitContainer: {
    minHeight: '200px',
    maxHeight: '400px',
    overflow: 'scroll'
  },
  multipleDebit: {
    width: '100%',
    border: '2px solid #003896',
    padding: '1rem',
    marginTop: '1.2rem',
    borderRadius: '0.7rem'
  },
  debitTitle: {
    color: '#003896',
    fontSize: '16px',
    fontWeight: 600
  },
  amount: {
    color: '#003896',
    fontSize: '16px',
    fontWeight: 800
  }
}));

const MultipleWithdrawalDialog = ({
  customerName,
  handleClose,
  open,
  walletId,
  walletBalance,
  userId
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const [withdrawalLoading, setWithdrawalLoading] = useState(false);
  const [multipleShipmentResults, setMultipleShipmentResults] = useRecoilState(
    multipleShipmentResultsState
  );

  const [getUserQuery, { loading: userLoading }]: any =
    useGetUserInformationQuery();
  const payFromWalletById = usePayFromWalletMutation();

  const handleDebitAmount = async (id: string, index: number) => {
    setWithdrawalLoading(true);

    const success = await payFromWalletById(id);
    if (success) {
      if (success?.payFromWallet?.isPaid) {
        const updatedResult = {
          ...multipleShipmentResults[index],
          data: [
            {
              ...multipleShipmentResults[index]?.data?.[0],
              isPaid: success?.payFromWallet?.isPaid
            }
          ]
        };
        setMultipleShipmentResults(
          multipleShipmentResults.map((result, resultIndex) =>
            resultIndex === index ? updatedResult : result
          )
        );
      }

      NotificationManager.success('Shipment successfully paid for', 'Paid');
      setWithdrawalLoading(false);

      getUserQuery(userId);
    } else {
      setWithdrawalLoading(false);
    }
  };

  const disableFinishButton = () => {
    const booleanArray = multipleShipmentResults?.map(
      result => result?.data?.[0]?.isPaid
    );
    return booleanArray.some(bool => !bool);
  };

  const newAmount = multipleShipmentResults
    ?.filter(result => result?.data?.[0]?.isPaid)
    ?.reduce((acc, result) => acc + result?.data?.[0]?.totalCharge / 100, 0);

  const loadingState = withdrawalLoading || userLoading;
  return (
    <>
      {Boolean(multipleShipmentResults && multipleShipmentResults?.length) && (
        <div>
          <BusyOverlay loading={loadingState} text='' />

          <DialogShell
            actionButtonText='Debit'
            handleClose={handleClose}
            hideActionButton={true}
            open={open}
            title=''
          >
            <form className={classes.form}>
              <>
                <div>
                  <Typography className={classes.title}>
                    {' '}
                    Debit for customer
                  </Typography>
                  <Typography variant='subtitle1' color='primary'>
                    {customerName}
                  </Typography>
                </div>

                <Divider variant='fullWidth' />

                <div className={classes.formContent}>
                  <Typography variant='subtitle1'>Wallet Balance</Typography>
                  <Typography variant='h5' color='primary'>
                    {toCurrency(walletBalance - newAmount, 'NGN')}
                  </Typography>
                </div>
                <div className={classes.debitContainer}>
                  {multipleShipmentResults?.map((receiver, receiverIndex) => {
                    return (
                      <div className={classes.multipleDebit}>
                        <div className='flex-row flex-justify-space-between'>
                          <p className={classes.title}>
                            Debit Amount {receiverIndex + 1}
                          </p>
                          <p className={classes.amount}>
                            {toCurrency(
                              toFixed(
                                receiver?.data?.[0]?.totalCharge
                                  ? receiver?.data?.[0]?.totalCharge / 100
                                  : 0
                              ),
                              'NGN'
                            )}
                          </p>
                        </div>
                        <SubmitButton
                          type='button'
                          text='Debit'
                          disabled={receiver?.data?.[0]?.isPaid}
                          onClick={() => {
                            handleDebitAmount(
                              receiver?.data?.[0]?.id,
                              receiverIndex
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className='mt-3'>
                  <SubmitButton
                    type='button'
                    text='Finish'
                    onClick={() => {
                      history.push('/dashboard');
                    }}
                    disabled={disableFinishButton()}
                  />
                </div>
              </>
            </form>
          </DialogShell>
        </div>
      )}
    </>
  );
};

export default MultipleWithdrawalDialog;
