export const toFixed = (input: any) => {
  let value = Number(input);

  if (!isNaN(value)) {
    return Number(value.toFixed(2));
  }

  return input;
};

export const toKoboFixed = (input: any) => {
  return toFixed(input) * 100;
};
