export enum ShipmentMenuOptionsEnum {
  PAY = 'Pay',
  EDIT = 'Edit',
  ADDITIONAL_CHARGE = 'Additional Charge',
  CHANGE_STATUS = 'Change Status',
  CANCEL = 'Cancel',
  ADD_TICKET = 'Add Ticket',
  BOOK_PICKUP = 'Book Pickup',
  DOWNLOAD_WAYBILL = 'Download Waybill',
  DOWNLOAD_INVOICE = 'Download Invoice',
  TRACK = 'Track',
  TRACK_ON_DHL = 'Track on DHL',
  TRACK_ON_UPS = 'Track on UPS',
  TRACK_ON_FEDEX = 'Track on FedEx',
  BOOK_YOU_PARCEL = 'Book YouParcel',
  DOWNLOAD_YOUPARCEL_INVOICE = 'Download YouParcel Invoice',
  DOWNLOAD_YOUPARCEL_WAYBILL = 'Download YouParcel Waybill',
  MANAGE_DOCUMENTS = 'Manage Documents',
  THIRD_PARTY_TRACKING_URL = 'Add Tracking Number',
  QUOTE_SEA_IMPORT = 'Quote Sea Import',
  QUOTE_SEA_EXPORT_LAST_MILE = 'Quote Sea Export Last Mile'
}
