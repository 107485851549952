export const statusMessageList = (
  value: string,
  receiverCountryCode?: string
) => {
  if (value === 'DeliveryInProgress') {
    return [
      'Shipment Dispatched for Delivery',
      'Doorstep Delivery in Progress'
    ];
  }

  if (value === 'ShipmentProcessing') {
    return [
      'Shipment processing for Export at Gateway',
      'Export clearance complete',
      'Exception, please contact support'
    ];
  }

  if (value === 'InTransit' && receiverCountryCode === 'US') {
    return [
      'Shipment In Transit to London, Heathrow, UK',
      'Shipment Arrived London, Heathrow, UK',
      'Shipment In Transit to JFK, New York, USA',
      'Shipment Arrived JFK, New York, USA',
      'Customs Clearance in Progress',
      'Customs Clearance Complete'
    ];
  }

  if (value === 'InTransit' && receiverCountryCode === 'GB') {
    return [
      'Shipment In Transit to London, Heathrow, UK',
      'Shipment Arrived London, Heathrow, UK',
      'Customs Clearance in Progress',
      'Customs Clearance Complete'
    ];
  }

  if (value === 'InTransit' && receiverCountryCode === 'CA') {
    return [
      'Shipment In Transit to  JFK, New York, USA',
      'Shipment Arrived  JFK, New York, USA',
      'Shipment In Transit to Pearson International, Toronto',
      'Shipment Arrived Pearson International, Toronto',
      'Customs Clearance in Progress',
      'Customs Clearance Complete'
    ];
  }

  return [];
};
