export const shipmentStatusDictionary = (value: string) => {
  const shipmentStatuses: any = {
    Draft: 'Draft',
    Cancelled: 'Cancelled',
    PendingConfirmation: 'Pending Confirmation',
    Confirmed: 'Confirmed',
    AwaitingPickUp: 'Awaiting Pick Up',
    InTransit: 'In Transit',
    AwaitingDropOff: 'Awaiting Drop Off',
    AssignedForDelivery: 'Assigned For Delivery',
    DeliveryInProgress: 'Delivery In Progress',
    Delivered: 'Delivered',
    CancellationPending: 'Cancellation Pending',
    PaymentPending: 'Payment Pending',
    DeliveryFailed: 'Delivery Failed',
    ClarificationNeeded: 'Clarification Needed',
    PickupCancelled: 'Pickup Cancelled',
    PickupDispatched: 'Pickup Dispatched',
    SuccessfullyPicked: 'Successfully Picked',
    PickupCancelledByCourier: 'Pickup Cancelled By Courier',
    PickupCancelledByMerchant: 'Pickup Cancelled By Merchant',
    PickupCancelledInternally: 'Pickup Cancelled Internally',
    PickupRescheduledByMerchant: 'Pickup Rescheduled By Merchant',
    ReceivedAtHub: 'Received At Hub',
    ArrivedNigeria: 'Arrived Nigeria',
    PickupInProgress: 'Pickup In Progress',
    ShipmentProcessing: 'Shipment Processing',
    PickupFailed: 'Pickup Failed',
    RiderAssigned: 'Rider Assigned',
    AwaitingDocumentUpload: 'Awaiting Document Upload',
    DocumentUploaded: 'Document Uploaded'
  };

  return shipmentStatuses[value] || 'Status Unavailable';
};
