import { useAuthToken, verifyToken } from 'helpers';

export const useShowAuthPage = () => {
  const [authToken]: any = useAuthToken();
  const user: any = verifyToken(authToken);

  const renderPage = (page: any) => {
    if (user.success) {
      window.location.href = '/dashboard';
    }
    return page;
  };
  return [renderPage];
};
