const shipmentStatuses = [
  'Confirmed',
  'Cancelled',
  'Delivered',
  'AwaitingPickUp',
  'InTransit',
  'DeliveryFailed',
  'CancellationPending',
  'PaymentPending',
  'AwaitingDropOff',
  'DeliveryInProgress',
  'AssignedForDelivery',
  'PendingConfirmation',
  'ClarificationNeeded',
  'ReceivedAtHub',
  'ArrivedNigeria',
  'PickupInProgress',
  'ShipmentProcessing',
  'PickupFailed',
  'RiderAssigned'
];

export const shipmentMessageSplitter = ';..';

export { shipmentStatuses };
