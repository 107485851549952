import gql from 'graphql-tag';

const GET_WALLET_BALANCE = gql`
  query {
    getWalletBalance {
      totalBalance
    }
  }
`;

const GET_TRANSACTION_REFERENCE = gql`
  query getReference($transaction: initTransactionInput!) {
    generateTransactionReference(referenceDetail: $transaction) {
      id
    }
  }
`;

const GET_USER = gql`
  query getUser($sub: String!) {
    user(userId: $sub) {
      topshipId
      id
      fullName
      phoneNumber
      email
      accountType
      businessInformation {
        id
        compliance
        isVerified
        businessName
        directors {
          fullName
          compliance
        }
      }
      wallet {
        id
        totalReceived
        totalSent
        totalBonusReceived
        totalBonusSpent
      }
    }
  }
`;

const GET_USERS = gql`
  query getUsers($filterOptions: UsersFilterInput) {
    users(filterOptions: $filterOptions) {
      totalCount
      currentPage
      totalCount
      totalPages
      list {
        id
        topshipId
        isEmailVerified
        isPhoneVerified
        email
        accountType
        phoneNumber
        country
        state
        city
        fullName
        createdDate
        source
        businessInformation {
          id
          compliance
          isVerified
          directors {
            compliance
          }
        }

        wallet {
          id
          totalSent
          totalReceived
          currency
          totalBonusReceived
          totalBonusSpent
        }
      }
    }
  }
`;

const GET_WALLET = gql`
  query {
    getWallet {
      id
      totalReceived
      totalSent
    }
  }
`;

const GET_TRANSACTIONS = gql`
  query fetchTransactions($filterOptions: TransactionFilter) {
    getTransactions(filter: $filterOptions) {
      totalCount
      currentPage
      totalPages
      list {
        id
        finalBalance
        initialBalance
        amount
        transactionReference
        description
        paymentMethod
        paymentChannel
        transactionType
        transactionStatus
        createdDate
        user {
          email
          fullName
        }
      }
    }
  }
`;

const GET_PAYSTACK_MESSAGES = gql`
  query fetchAllMessages($filterOptions: DeadLetterMessageFilter) {
    getAllMessages(filter: $filterOptions) {
      totalCount
      currentPage
      totalPages
      list {
        id
        createdDate
        updatedDate
        paystackEvent
        error
        retriesCount
        status
      }
    }
  }
`;

const GET_SHOPNSHIP = gql`
  query getShopnship($id: String!) {
    getShopnship(id: $id) {
      id
      initialCharge
      totalCharge
      couponApplied
      couponAppliedAmount
      insuranceCharge
      insuranceType
      user {
        id
        topshipId
        email
        wallet {
          id
          totalReceived
          totalSent
          currency
        }
      }
      transactions {
        transactionId
      }
    }
  }
`;

const GET_SHOPNSHIPS = gql`
  query shopnships($filter: ShopnshipFilter) {
    getShopnships(filter: $filter) {
      currentPage
      totalCount
      totalPages
      list {
        id
        createdDate
        updatedDate
        email
        phoneNumber
        shippingFrom
        packageName
        trackingId
        trackingNumber
        deliveryName
        deliveryAddress
        estimatedWeight
        itemDescription
        insuranceType
        insuranceCharge
        couponApplied
        couponAppliedAmount
        amountDue
        status
        receiverPaymentDetails
        uploadingMyDocuments
        proofOfId
        evidenceOfPurchase
        initialCharge
        isInitialChargePaid
        finalCharge
        totalCharge
        isFinalChargePaid
        user {
          id
          email
          phoneNumber
          fullName
          wallet {
            id
            totalReceived
            totalSent
            currency
          }
        }
      }
    }
  }
`;

const GET_COUPON = gql`
  query getCoupon($code: String!) {
    getCoupon(code: $code) {
      id
      code
      value
      type
      limit
      isActive
    }
  }
`;

const GET_UNVERIFIED_BUSINESSES = gql`
  query getUnverifiedBusinesses($input: FilterInput) {
    getUnverifiedBusinesses(input: $input) {
      currentPage
      totalCount
      totalPages
      list {
        id
        compliance
        isVerified
        createdDate
        businessName
        directors {
          fullName
          compliance
        }
        user {
          id
          email
          phoneNumber
        }
      }
    }
  }
`;

const GET_SHIPMENTS = gql`
  query shipments($filter: ShipmentFilter) {
    getShipments(filter: $filter) {
      currentPage
      totalCount
      totalPages
      list {
        id
        pricingTier
        createdDate
        updatedDate
        estimatedDeliveryDate
        trackingId
        thirdPartyTrackingId
        trackingUrl
        invoice
        label
        youparcelLabel
        youparcelInvoice
        itemCollectionMode
        shipmentStatus
        isPaid
        totalCharge
        valueAddedTaxCharge
        insuranceCharge
        pickupCharge
        images
        pickupDate
        additionalInformation
        pickupTrackingUrl
        logisticsBusinessAdditionalCharge
        logisticsBusinessId
        shipmentCharge
        shipmentRoute
        insuranceType
        couponApplied
        couponAppliedAmount
        totalWeight
        channel
        user {
          id
          topshipId
          fullName
          accountType
          email
          phoneNumber
          wallet {
            id
            totalReceived
            totalSent
            currency
          }
        }
        items {
          category
          description
          weight
          quantity
          value
          boxNumber
        }
        transaction {
          amount
          transactionReference
          description
          paymentMethod
          paymentChannel
        }
        senderDetail {
          email
          phoneNumber
          name
          addressLine1
          addressLine2
          addressLine3
          city
          state
          country
          countryCode
          postalCode
        }
        receiverDetail {
          email
          phoneNumber
          name
          addressLine1
          addressLine2
          addressLine3
          city
          state
          country
          countryCode
          postalCode
        }
        documents {
          id
          name
          content
          isVerified
        }
        lastMileTrackingUrl
      }
    }
  }
`;

const GET_SHIPMENT_DETAILS = gql`
  query getShipment($shipmentId: String!) {
    getShipment(id: $shipmentId) {
      id
      pricingTier
      updatedDate
      trackingId
      itemCollectionMode
      logisticsBusinessAdditionalCharge
      shipmentStatus
      insuranceType
      isPaid
      totalCharge
      pickupCharge
      estimatedDeliveryDate
      items {
        category
        description
        weight
        quantity
        value
        boxNumber
      }
      insuranceCharge
      senderDetail {
        addressLine1
        addressLine2
        addressLine3
        state
        city
        country
        countryCode
        name
        email
        phoneNumber
        postalCode
      }
      receiverDetail {
        addressLine1
        addressLine2
        addressLine3
        state
        city
        country
        countryCode
        name
        email
        phoneNumber
        postalCode
      }
      logisticsBusinessId {
        topshipId
      }
      user {
        topshipId
        id
        fullName
        phoneNumber
        email
        wallet {
          id
          totalReceived
          totalSent
          totalBonusReceived
          totalBonusSpent
        }
      }
    }
  }
`;

const GET_SAVED_ADDRESSES = gql`
  query {
    getAddresses {
      list {
        id
        name
        addressLine1
        phoneNumber
      }
    }
  }
`;

const GET_SHIPMENT_RATES = gql`
  query getAccurateShipmentRate(
    $discount: Boolean
    $shipmentDetail: GetDetailedRateInput!
  ) {
    getAccurateShipmentRate(
      addDiscount: $discount
      shipmentDetail: $shipmentDetail
    ) {
      mode
      cost
      duration
      currency
      pricingTier
    }
  }
`;

const GET_LAST_MILE_RATES = gql`
  query getLastMileRates($input: LastMileRatesInput!) {
    getLastMileRates(input: $input) {
      mode
      cost
      pricingTier
      duration
      logo
    }
  }
`;

const TRACK_SHIPMENT = gql`
  query trackShipment($trackingId: String!) {
    trackShipment(trackingId: $trackingId) {
      id
      status
      message
      createdDate
      createdBy {
        email
        fullName
      }
      updatedDate
      itemLocation
      transshipmentPoint
      shipment {
        id
        receiverDetail {
          name
          addressLine1
          phoneNumber
        }
      }
    }
  }
`;

const GET_WITHDRAWAL_REQUESTS = gql`
  query withdrawalRequests($filter: WithdrawalRequestFilter) {
    getAllWithdrawalRequests(filter: $filter) {
      currentPage
      totalCount
      totalPages
      list {
        id
        requestId
        createdDate
        updatedDate
        user {
          id
          topshipId
          email
          phoneNumber
          country
          fullName
        }
        amount
        bankDetail {
          id
          accountName
          accountNumber
          bankName
        }
        walletBalance
        currency
        withdrawalConfirmation
        approvalStatus
        fundsTransferStatus
        transferCode
      }
    }
  }
`;

const GET_COUNTRIES = gql`
  query {
    getCountries {
      code
      name
    }
  }
`;

const GET_STATES = gql`
  query getStates($countryCode: String!) {
    getStates(countryCode: $countryCode) {
      name
      countryCode
    }
  }
`;

const GET_CITIES = gql`
  query getCities($countryCode: String!) {
    getCities(countryCode: $countryCode) {
      cityName
      postcode
    }
  }
`;

const GET_CONFIG = gql`
  query {
    getConfiguration {
      commissionPercent
      minimumWithdrawal
      referralBonus
      autoApproveWithdrawal
      dhlShippingDate
      discount
      paystackCommissionPercent
    }
  }
`;

const GET_FEEDBACK = gql`
  query fetchFeedback($filterOptions: FeedbackFilter) {
    getFeedback(filter: $filterOptions) {
      totalCount
      currentPage
      totalPages
      list {
        id
        createdDate
        updatedDate
        message
        rating
        feedbackCategory
        user {
          id
          fullName
          email
          phoneNumber
          country
          state
          city
        }
      }
    }
  }
`;

const GET_SHIPMENT_METRICS = gql`
  query getShipmentMetrics($dateRange: DateRangeInput) {
    getShipmentMetrics(dateRange: $dateRange) {
      totalShipmentsSaved
      paid {
        total
        value
        data {
          id
          createdDate
          updatedDate
          trackingId
          trackingUrl
          thirdPartyTrackingId
          itemCollectionMode
          isPaid
          shipmentStatus
          pricingTier
          insuranceType
          insuranceCharge
          pickupCharge
          shipmentCharge
          commissionOnShipmentCharge
          totalCharge
          DHLCharge
          discount
          items {
            category
            description
            weight
            quantity
            value
            dimension {
              length
              width
              height
            }
          }
          senderName
          senderAddress
          receiverName
          receiverAddress
        }
      }
    }
  }
`;

const GET_PICKUP_RATES = gql`
  query getPickupRates($input: PickupRatesInput!) {
    getPickupRates(input: $input) {
      partner
      pickupCharge
      deliveryLocation
      partnerLogoUrl
      pickupId
    }
  }
`;

const GET_TICKETS = gql`
  query getTickets($filter: TicketFilter!) {
    getTickets(filter: $filter) {
      totalPages
      totalCount
      currentPage
      list {
        id
        status
        label
        title
        name
        issueId
        createdDate
        email
        phoneNumber
        shipment {
          id
          trackingId
        }
        shopnship {
          id
          trackingId
        }
        createdBy {
          id
          fullName
          email
          accountType
          phoneNumber
        }
        statuses {
          id
          status
          createdBy {
            fullName
          }
          createdDate
          message
        }
        notes {
          id
          message
          createdDate
          admin {
            fullName
          }
        }
      }
    }
  }
`;

export {
  GET_CITIES,
  GET_COUNTRIES,
  GET_COUPON,
  GET_CONFIG,
  GET_FEEDBACK,
  GET_PAYSTACK_MESSAGES,
  GET_SAVED_ADDRESSES,
  GET_SHIPMENTS,
  GET_SHIPMENT_DETAILS,
  GET_SHIPMENT_METRICS,
  GET_SHIPMENT_RATES,
  GET_SHOPNSHIPS,
  GET_STATES,
  GET_TRANSACTIONS,
  GET_TRANSACTION_REFERENCE,
  GET_WALLET,
  GET_WITHDRAWAL_REQUESTS,
  GET_WALLET_BALANCE,
  GET_USER,
  GET_USERS,
  TRACK_SHIPMENT,
  GET_SHOPNSHIP,
  GET_UNVERIFIED_BUSINESSES,
  GET_PICKUP_RATES,
  GET_TICKETS,
  GET_LAST_MILE_RATES
};
