import React from 'react';
import emptyIcon from '../../assets/svg/empty.svg';

type Props = {
  heading: string;
  subHeading?: string;
  style?: React.CSSProperties;
};

const EmptyList = ({ heading, subHeading, style }: Props) => (
  <div className='empty-list' style={style}>
    <img src={emptyIcon} alt='no data' />

    <div className=' mb-3'>
      {heading && <h1 className='empty-list__heading'>{heading}</h1>}
      <p className='empty-list__sub-heading '>{subHeading}</p>
    </div>
  </div>
);

export default EmptyList;
