export const itemCategories = [
  { category: 'Appliance', value: 'Appliance' },
  {
    category: 'Battery, liquid, electrical, and powder items',
    value: 'BatteryLiquidElectrical&PowderItems'
  },
  { category: 'Beauty products', value: 'BeautyProducts' },
  { category: 'Jewelry', value: 'Jewellery' },
  { category: 'Computer supplies', value: 'ComputerSupplies' },
  { category: 'Home decor & accessories', value: 'HomeDecor' },
  { category: 'Baby supplies', value: 'BabySupplies' },
  {
    category: 'Television & entertainment',
    value: 'TelevisionAndEntertainment'
  },
  { category: 'Original Artwork', value: 'OriginalArtwork' },
  { category: 'Art Prints', value: 'ArtPrints' },
  { category: 'Food Items', value: 'FoodItems' },
  { category: 'Medication', value: 'Medication' },
  {
    category: 'Fish',
    value: 'Fish'
  },

  { category: 'Herbs', value: 'Herbs' },

  { category: 'Kitchen accessories', value: 'KitchenAccessories' },
  { category: 'Furniture', value: 'Furniture' },
  { category: 'Fashion', value: 'Fashion' },
  { category: 'Education', value: 'Education' },
  { category: 'Gadgets', value: 'Gadgets' },
  { category: 'Solar panels & inverter', value: 'SolarPanelsAndInverter' },
  { category: 'Vehicle parts', value: 'VehicleParts' },
  { category: 'Clothing & textile', value: 'ClothingAndTextile' },
  { category: 'Sport accessories', value: 'SportAccessories' },
  { category: 'Gym equipment', value: 'GymEquipment' },
  { category: 'Drones', value: 'Drones' },
  { category: 'Document', value: 'Document' },
  { category: 'Others', value: 'Others' }
];

export const itemCategoriesChinaImport = [
  {
    category: 'Battery, liquid, electrical, and powder items',
    value: 'BatteryLiquidElectrical&PowderItems'
  },
  { category: 'Beauty products', value: 'BeautyProducts' },
  { category: 'Costume Jewelry', value: 'Jewellery' },
  { category: 'Medication', value: 'Medication' },

  { category: 'Fashion', value: 'Fashion' },
  { category: 'Phones and Laptops', value: 'Gadgets' },
  { category: 'Clothing & textile', value: 'ClothingAndTextile' }
];
