export const calculateInitial = (
  estimatedWeight: number,
  finalAmount: number,
  category: string,
  isGadget: any
) => {
  const ceiledWeight = Math.ceil(Number(estimatedWeight));
  return Math.ceil(
    ceiledWeight * 100 * (finalAmount * (isGadget(category) ? 2 : 1))
  );
};
