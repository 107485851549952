import { useHistory } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useRecoilState } from 'recoil';

import {
  ADD_CITY,
  ADD_COUNTRY,
  ADD_STATE,
  APPROVE_WITHDRAWAL_REQUEST,
  AUTHENTICATE_PAYSTACK_TRANSFER,
  CREATE_COUPON,
  CREATE_USER,
  CREATE_TOPSHIP_ADMIN,
  DECLINE_WITHDRAWAL_REQUEST,
  LOGIN_USER,
  SAVE_SHIPMENT,
  SAVE_CUSTOMER_SHIPMENT,
  PAY_FROM_WALLET,
  REPROCESS_MESSAGE,
  RESEND_FAILED_TRANSFER,
  SAVE_ADDRESS,
  DELETE_ADDRESS,
  UPDATE_CONFIG,
  UPDATE_MESSAGE,
  UPDATE_SHIPMENT,
  UPDATE_USER,
  UPDATE_WALLET_BALANCE,
  UPDATE_SHOPNSHIP,
  SHOP_N_SHIP,
  SAVE_CUSTOMER_SHOPNSHIP,
  EDIT_SHIPMENT,
  UPDATE_BUSINESS,
  CREATE_ADDITIONAL_CHARGE,
  BOOK_YOU_PARCEL,
  UPDATE_TICKET,
  CREATE_NOTE,
  CREATE_TICKET,
  UPLOAD_SHIPMENT_DOCUMENTS,
  BOOK_PICKUP
} from './definitions/mutations.def';
import { GET_SAVED_ADDRESSES, GET_USER } from './definitions/queries.def';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import {
  loginState,
  multipleSaveShipmentLoadingState,
  multipleShipmentRatesState,
  multipleShipmentResultsState,
  walletState
} from 'recoil-data/atoms';
import { parseGraphqlError, useAuthToken } from 'helpers';
import {
  CitiesInputType,
  ConfigurationInputType,
  CountriesInputType,
  CreateCouponInputType,
  FormattedResponseType,
  IShipment,
  StatesInputType,
  UserType
} from 'types';
import Client from 'services/client';
import React from 'react';
import { CreateAdditionalChargeInput } from 'types/transactions';
import { TicketStatusTrackType } from 'types/tickets';
import { GraphQLError } from 'graphql';

const useCreateUserMutation = () => {
  const client = useApolloClient();

  try {
    const createUserMutation = async (userInfo: any) => {
      const { data } = await client.mutate({
        mutation: CREATE_USER,
        variables: {
          userInfo
        }
      });

      return data;
    };

    return createUserMutation;
  } catch (error) {
    NotificationManager.error(parseGraphqlError(error), 'Error');
  }
};

const useCreateTopshipAdminMutation = () => {
  const client = useApolloClient();

  const createAdminMutation = async (userInfo: any) => {
    try {
      const { data } = await client.mutate({
        mutation: CREATE_TOPSHIP_ADMIN,
        variables: {
          userInfo
        }
      });

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return createAdminMutation;
};

const useLoginMutation = () => {
  const history = useHistory();
  const [, setToken] = useAuthToken() as any;
  const [, setLoggedIn] = useRecoilState(loginState);
  const [loginUser, loginResult] = useMutation(LOGIN_USER, {
    onCompleted: data => {
      const {
        login: { accessToken, userType }
      } = data;
      localStorage.setItem('type', userType);
      setToken(process.env.REACT_APP_TOKEN_KEY, accessToken);
      setLoggedIn(true);
      history.push('/dashboard');
    },
    onError: error => {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  });

  const login = (data: any) => {
    loginUser({
      variables: { existingUser: data }
    });
  };

  return [login, loginResult];
};

const useSaveShipmentMutation = () => {
  const [saveShipment, saveShipmentResult] = useMutation(SAVE_SHIPMENT, {
    onCompleted: data => {},
    onError: error => {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  });

  const saveShipmentDetails = (data: any) => {
    saveShipment({
      variables: { shipmentDetails: data }
    });
  };

  return [saveShipmentDetails, saveShipmentResult];
};

const useUpdateBusinessInfoMutation = (id?: string, onCompleted?: any) => {
  const [updateBusiness, updateBusinessResult] = useMutation(UPDATE_BUSINESS, {
    onCompleted: data => {
      onCompleted && onCompleted(data);
    },
    onError: error => {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    },
    ...(id
      ? {
          refetchQueries: [
            {
              query: GET_USER,
              variables: {
                sub: id
              }
            },
            'getUser'
          ]
        }
      : {})
  });

  const updateBusinessInfo = (businessInfo: any, id: string) => {
    updateBusiness({
      variables: {
        update: businessInfo,
        businessId: id
      }
    });
  };
  return [updateBusinessInfo, updateBusinessResult];
};

const useSaveCustomerShipmentMutation = () => {
  const client = useApolloClient();

  const saveCustomerShipmentDetails = async (
    coupon: string | null,
    discount: boolean = false,
    shipmentData: any,
    email: string
  ) => {
    try {
      const { data } = await client.mutate({
        mutation: SAVE_CUSTOMER_SHIPMENT,
        variables: {
          coupon,
          discount,
          shipmentDetails: shipmentData,
          customerInfo: email
        }
      });

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return saveCustomerShipmentDetails;
};

const useEditCustomerShipmentMutation = () => {
  const client = useApolloClient();

  const editCustomerShipmentDetails = async (
    coupon: string | null,
    discount: boolean = false,
    shipmentData: any,
    id: string
  ) => {
    try {
      const { data } = await client.mutate({
        mutation: EDIT_SHIPMENT,
        variables: {
          coupon,
          discount,
          newShipment: shipmentData,
          shipmentId: id
        }
      });

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return editCustomerShipmentDetails;
};
const useSaveCustomerShopnshipMutation = () => {
  const client = useApolloClient();

  const saveCustomerShopnshipDetails = async (
    coupon: string | null,
    shopnshipData: any,
    email: string
  ) => {
    try {
      const { data } = await client.mutate({
        mutation: SAVE_CUSTOMER_SHOPNSHIP,
        variables: {
          coupon,
          details: shopnshipData,
          customerInfo: email
        }
      });
      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };
  return saveCustomerShopnshipDetails;
};

const useSaveMultipleShipmentsMutation = () => {
  const client = useApolloClient();
  const [, setMultipleSaveShipmentLoading] = useRecoilState(
    multipleSaveShipmentLoadingState
  );
  const [, setMultipleShipmentResults] = useRecoilState(
    multipleShipmentResultsState
  );
  const [multipleShipmentRates, setMultipleShipmentRates] = useRecoilState(
    multipleShipmentRatesState
  );

  const saveShipmentDetails = async (
    coupon: string | null,
    discount: boolean = false,
    shipmentData: any,
    email: string
  ) => {
    try {
      const { data } = await client.mutate({
        mutation: SAVE_CUSTOMER_SHIPMENT,
        variables: {
          coupon,
          discount,
          shipmentDetails: shipmentData,
          customerInfo: email
        }
      });
      NotificationManager.success('Shipment saved!');
      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  const saveMultipleShipments = async (
    shipments: any[],
    userEmail: string,
    initialValues: IShipment,
    setInitialValues: React.Dispatch<React.SetStateAction<IShipment>>,
    discount?: boolean
  ) => {
    const responses = [];
    setMultipleSaveShipmentLoading({
      loading: true,
      completed: false
    });

    for await (const shipment of shipments) {
      const couponCode = shipment.couponCode;
      delete shipment.couponCode;
      const response = await saveShipmentDetails(
        couponCode,
        discount,
        shipment,
        userEmail
      );
      responses.push(response);
    }
    const formattedResponse = responses.map((response, index) => {
      return { index, data: response?.saveCustomerShipment };
    });

    const formattedResponseId = formattedResponse.every((response, index) => {
      return response?.data?.[0]?.id;
    });

    if (formattedResponseId) {
      setMultipleShipmentResults(formattedResponse);
    } else {
      const errorIndex1 = [];
      errorIndex1.push(formattedResponse?.findIndex(res => !res?.data?.[0].id));

      const errorResponseArr: FormattedResponseType[] = [];

      errorResponseArr.push(
        ...formattedResponse?.filter(res => !res?.data?.[0].id)
      );

      const remainingReceivers = initialValues.multipleReceivers.filter(
        (_, resIndex) => {
          const errorIndexes = errorResponseArr?.map(err => err?.index);
          return errorIndexes.includes(resIndex);
        }
      );

      setInitialValues({
        ...initialValues,
        multipleReceivers: remainingReceivers
      });

      setMultipleShipmentResults([]);
      const newShipmentRates = multipleShipmentRates.filter((_, rateIndex) => {
        const errorIndexes = errorResponseArr?.map(err => err?.index);
        return errorIndexes.includes(rateIndex);
      });
      setMultipleShipmentRates(newShipmentRates);
      setTimeout(() => {
        NotificationManager.warning(
          'MSG TWO Please note that there is an issue with the information entered in one of your shipments, other shipments may be saved as draft, and you can pay for them later, on your dashboard.',
          'Warning'
        );
      }, 2000);
    }

    setMultipleSaveShipmentLoading({
      loading: false,
      completed: true
    });
    return formattedResponse;
  };

  return saveMultipleShipments;
};

const useShopnShipMutation = (onCompleted: {
  (data: any): void;
  (arg0: any): any;
}) => {
  const [saveShopnship, saveShopnshipResult] = useMutation(SHOP_N_SHIP, {
    onCompleted: data => {
      onCompleted && onCompleted(data);
      NotificationManager.success('Shopnship successfully saved!');
    },
    onError: error => {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  });

  const shopnship = (variables: any) => {
    saveShopnship({
      variables
    });
  };

  return [shopnship, saveShopnshipResult];
};

const useUpdateWalletBalanceMutation = () => {
  const [wallet, setWalletState] = useRecoilState(walletState);
  const [updateWalletBalance, updateWalletBalanceResult] = useMutation(
    UPDATE_WALLET_BALANCE,
    {
      onCompleted: data => {
        if (data) {
          const { finalBalance, finalBonusBalance } = data.updateWalletBalance;

          setWalletState({
            ...wallet,
            walletBalance: finalBalance / 100,
            bonusBalance: finalBonusBalance / 100
          });
        }
      },
      onError: error => {
        NotificationManager.error(parseGraphqlError(error), 'Error');
      }
    }
  );

  const updateBalance = (data: any) => {
    updateWalletBalance({
      variables: { walletBalance: data }
    });
  };

  return [updateBalance, updateWalletBalanceResult];
};

const useUpdateShipmentMutation = () => {
  const client = useApolloClient();

  const updateShipmentMutation = async (
    id: string,
    update: any,
    statusMessage?: string
  ) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_SHIPMENT,
        variables: {
          update,
          statusMessage,
          id
        }
      });

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return updateShipmentMutation;
};

const useBookYouParcelMutation = () => {
  const client = useApolloClient();

  const bookYouParcelMutation = async (shipmentId: string) => {
    try {
      const { data } = await client.query({
        query: BOOK_YOU_PARCEL,
        variables: {
          shipmentId
        },
        fetchPolicy: 'no-cache'
      });
      if (data) {
        NotificationManager.success('YouParcel successfully booked');
      }

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
      // throw error;
    }
  };

  return bookYouParcelMutation;
};

const useUpdateUserMutation = () => {
  const client = useApolloClient();

  const updateUserMutation = async (update: UserType, topshipId: string) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_USER,
        variables: {
          update,
          topshipId
        }
      });

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return updateUserMutation;
};

const useUpdateShopnshipMutation = () => {
  const client = useApolloClient();

  const updateShopnshipMutation = async (update: any, id: string) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_SHOPNSHIP,
        variables: {
          update,
          id
        }
      });

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return updateShopnshipMutation;
};

const useReprocessTransactionMutation = () => {
  const client = useApolloClient();

  const reprocessTransactionMutation = async (id: string) => {
    const { data } = await client.mutate({
      mutation: REPROCESS_MESSAGE,
      variables: {
        id
      }
    });

    return data;
  };

  return reprocessTransactionMutation;
};

const useUpdateMessageMutation = () => {
  const client = useApolloClient();

  const updateMessageMutation = async (id: string, messageInput: any) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_MESSAGE,
        variables: {
          messageInput,
          id
        }
      });

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return updateMessageMutation;
};

const usePayFromWalletMutation = () => {
  const client = useApolloClient();

  const payFromWalletById = async (id: string) => {
    try {
      const { data } = await client.mutate({
        mutation: PAY_FROM_WALLET,
        variables: {
          detail: {
            shipmentId: id
          }
        }
      });
      if (data && id.split('/').length > 1) {
        NotificationManager.success(
          'Shopnship successfully paid for',
          'Success'
        );
      }

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return payFromWalletById;
};

const useSaveAddressMutation = () => {
  const [saveAddress, saveAddressResults] = useMutation(SAVE_ADDRESS, {
    onCompleted: data => {
      const client = Client();
      client.resetStore();
    }
  });

  const saveCustomerAddress = (address: any) => {
    saveAddress({
      variables: { address }
    });
  };

  return [saveCustomerAddress, saveAddressResults];
};

const useDeleteAddressMutation = () => {
  const [deleteAddress, deleteAddressResults] = useMutation(DELETE_ADDRESS, {
    onCompleted: data => {
      NotificationManager.success('Address deleted!');
      const client = Client();
      client.resetStore();
    },
    onError: error => {
      NotificationManager.error(parseGraphqlError(error));
    },
    update: (cache, { data }) => {
      const allAddresses: any = cache.readQuery({
        query: GET_SAVED_ADDRESSES
      });
      const { list } = allAddresses.getAddresses;
      const updatedAddresses = list.filter(
        (list: any) => list.id !== data.deleteAddress.id
      );
      cache.writeQuery({
        query: GET_SAVED_ADDRESSES,
        data: { getAddresses: { list: updatedAddresses } }
      });
    }
  });

  const deleteCustomerAddress = (id: any) => {
    deleteAddress({
      variables: { id }
    });
  };

  return [deleteCustomerAddress, deleteAddressResults];
};

const useDeclineWithdrawalRequestMutation = () => {
  const client = useApolloClient();

  const declineWithdrawalRequestMutation = async (id: string) => {
    try {
      const { data } = await client.mutate({
        mutation: DECLINE_WITHDRAWAL_REQUEST,
        variables: {
          id
        }
      });

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return declineWithdrawalRequestMutation;
};

const useApproveWithdrawalRequestMutation = () => {
  const client = useApolloClient();

  const approveWithdrawalRequestMutation = async (id: string) => {
    try {
      const { data } = await client.mutate({
        mutation: APPROVE_WITHDRAWAL_REQUEST,
        variables: {
          id
        }
      });

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return approveWithdrawalRequestMutation;
};

const useAuthenticatePaystackTransferMutation = () => {
  const client = useApolloClient();

  const authenticatePaystackTransferMutation = async (
    otp: string,
    transferCode: string
  ) => {
    try {
      const { data } = await client.mutate({
        mutation: AUTHENTICATE_PAYSTACK_TRANSFER,
        variables: {
          otp,
          transferCode
        }
      });

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return authenticatePaystackTransferMutation;
};

const useResendFailedTransferMutation = () => {
  const client = useApolloClient();

  const resendFailedTransferMutation = async (id: string) => {
    try {
      const { data } = await client.mutate({
        mutation: RESEND_FAILED_TRANSFER,
        variables: {
          id
        }
      });

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return resendFailedTransferMutation;
};

const useUpdateConfigurationMutation = () => {
  const client = useApolloClient();

  const updateConfigurationMutation = async (
    configurationInput: ConfigurationInputType
  ) => {
    try {
      const {
        autoApproveWithdrawal,
        commissionPercent,
        dhlShippingDate,
        discount,
        minimumWithdrawal,
        paystackCommissionPercent,
        referralBonus
      } = configurationInput;

      const { data } = await client.mutate({
        mutation: UPDATE_CONFIG,
        variables: {
          configure: {
            autoApproveWithdrawal,
            commissionPercent,
            dhlShippingDate,
            discount,
            minimumWithdrawal,
            paystackCommissionPercent,
            referralBonus
          }
        }
      });

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return updateConfigurationMutation;
};

const useCreateCouponMutation = () => {
  const client = useApolloClient();

  const createCouponMutation = async (
    createCouponInput: CreateCouponInputType
  ) => {
    try {
      const { value, limit, type } = createCouponInput;

      const { data } = await client.mutate({
        mutation: CREATE_COUPON,
        variables: {
          input: {
            value,
            limit,
            type
          }
        }
      });

      NotificationManager.success(
        `Successfully added coupon! ${data.createCouponCode.code}`,
        500000
      );

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return createCouponMutation;
};

const useAddCityMutation = () => {
  const client = useApolloClient();

  const addCityMutation = async (city: CitiesInputType) => {
    try {
      const { data } = await client.mutate({
        mutation: ADD_CITY,
        variables: { city }
      });
      NotificationManager.success(`Successfully added city`);

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return addCityMutation;
};

const useAddCountryMutation = () => {
  const client = useApolloClient();

  const addCountryMutation = async (country: CountriesInputType) => {
    try {
      const { data } = await client.mutate({
        mutation: ADD_COUNTRY,
        variables: { country }
      });
      NotificationManager.success(`Successfully added country`);

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return addCountryMutation;
};

const useAddStatesMutation = () => {
  const client = useApolloClient();

  const addStatesMutation = async (state: StatesInputType) => {
    try {
      const { data } = await client.mutate({
        mutation: ADD_STATE,
        variables: { state }
      });
      NotificationManager.success(`Successfully added state`);

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return addStatesMutation;
};

const useCreateAdditionalChargeMutation = () => {
  const client = useApolloClient();

  const createAdditionalCharge = async (input: CreateAdditionalChargeInput) => {
    try {
      const { data } = await client.mutate({
        mutation: CREATE_ADDITIONAL_CHARGE,
        variables: { input }
      });

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return createAdditionalCharge;
};

const useUpdateTicketMutation = () => {
  const [update, updateTicketResult] = useMutation(UPDATE_TICKET, {
    onError: (error: any) => {
      if (error?.networkError?.result) {
        return error?.networkError?.result?.errors.forEach(
          (error: GraphQLError) => {
            return NotificationManager.error(
              (error.extensions?.exception as any)?.message || error.message
            );
          }
        );
      }

      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  });

  const updateTicket = (input: {
    id: string;
    ticketStatus: TicketStatusTrackType;
  }) => {
    return update({ variables: { input } });
  };

  return { updateTicket, updateTicketResult };
};

const useCreateNoteMutation = () => {
  const [create, createNoteResult] = useMutation(CREATE_NOTE, {
    onError: (error: any) => {
      if (error?.networkError?.result) {
        return error?.networkError?.result?.errors.forEach(
          (error: GraphQLError) => {
            return NotificationManager.error(
              (error.extensions?.exception as any)?.message || error.message
            );
          }
        );
      }

      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  });

  const createNote = (input: { ticketId: string; message: string }) => {
    return create({ variables: { input } });
  };

  return { createNote, createNoteResult };
};

const useCreateTicketMutation = () => {
  const [create, createTicketResult] = useMutation(CREATE_TICKET, {
    onError: (error: any) => {
      if (error?.networkError?.result) {
        return error?.networkError?.result?.errors.forEach(
          (error: GraphQLError) => {
            return NotificationManager.error(
              (error.extensions?.exception as any)?.message || error.message
            );
          }
        );
      }

      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  });

  const createTicket = (input: any) => {
    return create({ variables: { input } });
  };

  return { createTicket, createTicketResult };
};

const useUploadShipmentDocumentsMutation = () => {
  const [upload, uploadShipmentDocumentsResult] = useMutation(
    UPLOAD_SHIPMENT_DOCUMENTS,
    {
      onError: (error: any) => {
        if (error?.networkError?.result) {
          return error?.networkError?.result?.errors.forEach(
            (error: GraphQLError) => {
              return NotificationManager.error(
                (error.extensions?.exception as any)?.message || error.message
              );
            }
          );
        }

        NotificationManager.error(parseGraphqlError(error), 'Error');
      }
    }
  );

  const uploadShipmentDocuments = (input: any) => {
    return upload({ variables: { input } });
  };

  return { uploadShipmentDocuments, uploadShipmentDocumentsResult };
};

const useBookPickupMutation = (input: {
  onCompleted?: (data: any) => any;

  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [book, bookPickupResult] = useMutation(BOOK_PICKUP, {
    onCompleted: data => {
      input?.onCompleted?.(data);
    },
    onError: (error: any) => {
      input?.setLoading?.(false);

      if (error?.networkError?.result) {
        return error?.networkError?.result?.errors.forEach(
          (error: GraphQLError) => {
            return NotificationManager.error(
              (error.extensions?.exception as any)?.message || error.message
            );
          }
        );
      }

      return NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  });

  const bookPickup = (shipmentId: string) => {
    return book({
      variables: {
        shipmentId
      }
    });
  };

  return { bookPickup, bookPickupResult };
};

export {
  useAddCityMutation,
  useAddCountryMutation,
  useAddStatesMutation,
  useApproveWithdrawalRequestMutation,
  useAuthenticatePaystackTransferMutation,
  useCreateCouponMutation,
  useCreateUserMutation,
  useCreateTopshipAdminMutation,
  useDeclineWithdrawalRequestMutation,
  useLoginMutation,
  useDeleteAddressMutation,
  usePayFromWalletMutation,
  useReprocessTransactionMutation,
  useResendFailedTransferMutation,
  useSaveCustomerShipmentMutation,
  useSaveShipmentMutation,
  useSaveAddressMutation,
  useUpdateConfigurationMutation,
  useUpdateMessageMutation,
  useUpdateShipmentMutation,
  useUpdateUserMutation,
  useUpdateWalletBalanceMutation,
  useUpdateShopnshipMutation,
  useShopnShipMutation,
  useSaveMultipleShipmentsMutation,
  useSaveCustomerShopnshipMutation,
  useEditCustomerShipmentMutation,
  useUpdateBusinessInfoMutation,
  useBookYouParcelMutation,
  useCreateAdditionalChargeMutation,
  useUpdateTicketMutation,
  useCreateNoteMutation,
  useCreateTicketMutation,
  useUploadShipmentDocumentsMutation,
  useBookPickupMutation
};
