import { useApolloClient, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useRecoilState } from 'recoil';
import { useMutation, useMutation as useReactQueryMutation } from 'react-query';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import {
  transactionReferenceState,
  walletState,
  saveShipmentRateState,
  saveShipmentDetailsState,
  userNumberState,
  shipmentNumberState,
  shipmentTypeState,
  shopNShipNumberState,
  transactionsNumberState,
  deadlettersNumberState,
  saveUserInformationState,
  withdrawalRequestNumberState,
  saveConfigurationState,
  selectedInsurancePlan,
  feedbackNumberState,
  multipleShipmentRatesState,
  multipleRateLoadingState,
  shopnshipRateState,
  shipmentStatusState,
  couponResultState,
  businessNumberState,
  ticketNumberState,
  saveLastMileRateState
} from 'recoil-data/atoms';
import {
  getItemNairaValue,
  getInsurancePlan,
  getShipmentType,
  parseGraphqlError,
  shipmentStatusDictionary
} from 'helpers';
import { useGetUserPayload } from 'hooks';
import { transactionTypesEnum } from 'enums';
import {
  GET_WALLET_BALANCE,
  GET_TRANSACTION_REFERENCE,
  GET_TRANSACTIONS,
  GET_USER,
  GET_USERS,
  GET_WALLET,
  GET_WITHDRAWAL_REQUESTS,
  GET_SHIPMENTS,
  GET_SHIPMENT_DETAILS,
  GET_SHIPMENT_METRICS,
  GET_SHOPNSHIPS,
  GET_SAVED_ADDRESSES,
  GET_SHIPMENT_RATES,
  TRACK_SHIPMENT,
  GET_PAYSTACK_MESSAGES,
  GET_CONFIG,
  GET_COUNTRIES,
  GET_COUPON,
  GET_STATES,
  GET_CITIES,
  GET_FEEDBACK,
  GET_SHOPNSHIP,
  GET_UNVERIFIED_BUSINESSES,
  GET_PICKUP_RATES,
  GET_TICKETS,
  GET_LAST_MILE_RATES
} from './definitions/queries.def';
import { getDhlCities, getShopnshipCurrencyRate } from './definitions/rest.api';
import { IShipment, PickupRatesInput } from 'types';
import { shipmentOptions } from 'constant';
import { ApolloError } from 'apollo-client';

const useGetWalletBalanceQuery = () => {
  return useQuery(GET_WALLET_BALANCE, {
    onCompleted: data => {
      localStorage.setItem('tempToken', 'null');
    },
    onError: error => {}
  });
};

const useGetUserQuery = () => {
  const userPayload: any = useGetUserPayload();
  return useQuery(GET_USER, {
    variables: { sub: userPayload.sub },
    onCompleted: data => {},
    fetchPolicy: 'no-cache'
  });
};

const useGetUserInformationQuery = (onCompleted?: any, isImport = false) => {
  const [, setWalletState] = useRecoilState(walletState);
  const [shipmentDetails, setShipmentDetails] = useRecoilState(
    saveShipmentDetailsState
  );
  const [, saveUserInformation] = useRecoilState(saveUserInformationState);

  const [getUser, getUserQueryResult] = useLazyQuery(GET_USER, {
    onCompleted: data => {
      if (data) {
        const {
          id,
          totalReceived,
          totalSent,
          totalBonusReceived,
          totalBonusSpent
        } = data.user.wallet;
        const { senderDetail, receiverDetail } = shipmentDetails;

        setShipmentDetails({
          ...shipmentDetails,
          ...(!isImport && {
            senderDetail: {
              ...senderDetail,
              name: data.user.fullName,
              email: data.user.email,
              phoneNumber: data.user.phoneNumber
            }
          }),
          ...(isImport && {
            receiverDetail: {
              ...receiverDetail,
              name: data.user.fullName,
              email: data.user.email,
              phoneNumber: data.user.phoneNumber
            }
          })
        });

        saveUserInformation({
          topshipId: data.user.topshipId,
          id: data.user.id,
          fullName: data.user.fullName,
          phoneNumber: data.user.phoneNumber,
          email: data.user.email,
          accountType: data.user.accountType,
          businessInformation: {
            isVerified: data?.user?.businessInformation?.isVerified,
            directors: data?.user?.businessInformation?.directors
          },
          wallet: {
            id: data.user.wallet.id,
            totalReceived: data.user.wallet.totalReceived,
            totalSent: data.user.wallet.totalSent,
            totalBonusReceived: data.user.wallet.totalBonusReceived,
            totalBonusSpent: data.user.wallet.totalBonusSpent
          }
        });

        setWalletState({
          walletId: id,
          walletBalance: (totalReceived - totalSent) / 100,
          bonusBalance: (totalBonusReceived - totalBonusSpent) / 100
        });
        onCompleted && onCompleted(data);
      }
    },
    onError: error => {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  });

  const getUserQuery = (id: string) => {
    getUser({
      variables: {
        sub: id
      }
    });
  };

  return [getUserQuery, getUserQueryResult];
};

const useGenerateTransactionReferenceQuery = () => {
  const [, setReference] = useRecoilState(transactionReferenceState);
  const [generateReference, generateReferenceResult] = useLazyQuery(
    GET_TRANSACTION_REFERENCE,
    {
      onCompleted: data => {
        const { id } = data.generateTransactionReference;
        setReference(id);
      },
      onError: error => {
        NotificationManager.error(parseGraphqlError(error), 'Error');
      },
      fetchPolicy: 'no-cache'
    }
  );

  const generateTransactionReference = (
    id: string,
    transactionType: string
  ) => {
    generateReference({
      variables: {
        transaction: {
          id,
          transactionType
        }
      }
    });
  };

  return [generateTransactionReference, generateReferenceResult];
};

const useGetUsersQuery = () => {
  const client = useApolloClient();
  const [, setUserNumber] = useRecoilState(userNumberState);

  const getUsersQuery = async (
    page: number,
    take: number,
    search: string,
    otherFilters?: any
  ) => {
    const { data } = await client.query({
      query: GET_USERS,
      variables: {
        filterOptions: {
          page: page,
          take: take,
          keyword: search,
          ...(otherFilters || {})
        }
      },
      fetchPolicy: 'no-cache'
    });

    setUserNumber(data?.users?.totalCount);
    return data;
  };

  return getUsersQuery;
};

const useGetTransactionsQuery = () => {
  const client = useApolloClient();
  const [, setTransactionsNumber] = useRecoilState(transactionsNumberState);

  const getTransactionsQuery = async (
    page: number,
    take: number,
    search: string
  ) => {
    const { data } = await client.query({
      query: GET_TRANSACTIONS,
      variables: {
        filterOptions: {
          page: page,
          take: take,
          keyword: search
        }
      }
    });

    setTransactionsNumber(data?.getTransactions?.totalCount);
    return data;
  };

  return getTransactionsQuery;
};

const useGetFeedbackQuery = () => {
  const client = useApolloClient();
  const [, setFeedbackNumber] = useRecoilState(feedbackNumberState);

  const getFeedbackQuery = async (
    page: number,
    take: number,
    search: string
  ) => {
    const { data } = await client.query({
      query: GET_FEEDBACK,
      variables: {
        filterOptions: {
          page: page,
          take: take,
          keyword: search
        }
      }
    });

    setFeedbackNumber(data?.getFeedback?.totalCount);
    return data;
  };

  return getFeedbackQuery;
};

const useGetPaystackMessagesQuery = () => {
  const client = useApolloClient();
  const [, setDeadlettersNumber] = useRecoilState(deadlettersNumberState);

  const getDeadlettersQuery = async (
    page: number,
    take: number,
    search: string
  ) => {
    const { data } = await client.query({
      query: GET_PAYSTACK_MESSAGES,
      variables: {
        filterOptions: {
          page: page,
          take: take,
          keyword: search
        }
      },
      fetchPolicy: 'no-cache'
    });

    setDeadlettersNumber(data?.getAllMessages?.totalCount);
    return data;
  };

  return getDeadlettersQuery;
};

const useGetWalletQuery = () => {
  const [, setWalletState] = useRecoilState(walletState);
  const [generateReference]: any = useGenerateTransactionReferenceQuery();
  return useQuery(GET_WALLET, {
    onCompleted: data => {
      if (data) {
        const {
          id: walletId,
          totalReceived,
          totalSent,
          totalBonusSpent,
          totalBonusReceived
        } = data.getWallet;

        setWalletState({
          walletId,
          walletBalance: totalReceived - totalSent,
          bonusBalance: totalBonusReceived - totalBonusSpent
        });

        generateReference(walletId, transactionTypesEnum.DEPOSIT);
      }
    }
  });
};

const useGetShipmentsQuery = () => {
  const client = useApolloClient();
  const [, setShipmentStatus] = useRecoilState(shipmentStatusState);

  const [, setShipmentNumber] = useRecoilState(shipmentNumberState);

  const getShipmentsQuery = async (
    page: number,
    take: number,
    search: string,
    otherFilters?: any
  ) => {
    const { data } = await client.query({
      query: GET_SHIPMENTS,
      variables: {
        filter: {
          page,
          take,
          keyword: search,
          ...(otherFilters || {})
        }
      },
      fetchPolicy: 'no-cache'
    });
    data?.getShipments?.list?.map((item: any) =>
      setShipmentStatus(item.shipmentStatus)
    );

    setShipmentNumber(data?.getShipments?.totalCount);
    return data;
  };

  return getShipmentsQuery;
};

const useGetTicketsQuery = () => {
  const client = useApolloClient();

  const [, setTicketNumber] = useRecoilState(ticketNumberState);

  const getTicketsQuery = async (
    page: number,
    take: number,
    search: string,
    otherFilters?: any
  ) => {
    const { data } = await client.query({
      query: GET_TICKETS,
      variables: {
        filter: {
          page,
          take,
          keyword: search,
          ...(otherFilters || {})
        }
      },
      fetchPolicy: 'no-cache'
    });

    if (data?.getTickets) {
      setTicketNumber(data?.getTickets?.totalCount);
    }

    return data;
  };

  return getTicketsQuery;
};

const useGetUnverifiedBusinesses = () => {
  const client = useApolloClient();
  const [, setUnverifiedBusinessNumber] = useRecoilState(businessNumberState);

  const getUnverifiedBusinessesQuery = async (
    page: number,
    take: number,
    search: string,
    otherFilters?: any
  ) => {
    const { data } = await client.query({
      query: GET_UNVERIFIED_BUSINESSES,
      variables: {
        input: {
          page,
          take,
          keyword: search,
          ...(otherFilters || {})
        }
      },
      fetchPolicy: 'no-cache'
    });

    setUnverifiedBusinessNumber(data?.getUnverifiedBusinesses?.totalCount);
    return data;
  };

  return getUnverifiedBusinessesQuery;
};

const useGetOneShopnshipQuery = (onCompleted: any) => {
  const [getShopnship, shopNShipResult] = useLazyQuery(GET_SHOPNSHIP, {
    onCompleted: data => {
      onCompleted && onCompleted(data);
    },
    onError: error => {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  });

  const getShopnshipQuery = (id: any) => {
    getShopnship({
      variables: { id }
    });
  };

  return [getShopnshipQuery, shopNShipResult];
};

const useGetShopNShipsQuery = () => {
  const client = useApolloClient();
  const [, setShopNShipNumber] = useRecoilState(shopNShipNumberState);

  const getShipmentsQuery = async (
    page?: number,
    take?: number,
    search?: string,
    otherFilters?: any
  ) => {
    const { data } = await client.query({
      query: GET_SHOPNSHIPS,
      variables: {
        filter: {
          page,
          take,
          keyword: search,
          ...(otherFilters || {})
        }
      },
      fetchPolicy: 'no-cache'
    });

    setShopNShipNumber(data?.getShopnships?.totalCount);
    return data;
  };

  return getShipmentsQuery;
};

const useGetShipmentMetricQuery = () => {
  const client = useApolloClient();

  const getShipmentsQuery = async (from: Date, to: Date) => {
    try {
      const { data } = await client.query({
        query: GET_SHIPMENT_METRICS,
        variables: {
          dateRange: {
            from,
            to
          }
        },
        fetchPolicy: 'no-cache'
      });

      return data;
    } catch (error) {
      NotificationManager.error(parseGraphqlError(error), 'Error');
    }
  };

  return getShipmentsQuery;
};

const useGetShipmentDetailsQuery = () => {
  const [, setShipmentDetails] = useRecoilState(saveShipmentDetailsState);
  const [, setShipmentType] = useRecoilState(shipmentTypeState);
  const [, setInsurancePlan] = useRecoilState(selectedInsurancePlan);
  const [, setCouponData] = useRecoilState(couponResultState);
  const [, saveUserInformation] = useRecoilState(saveUserInformationState);
  const [, setWalletState] = useRecoilState(walletState);

  const [getShipmentDetails, getShipmentDetailsQueryResult] = useLazyQuery(
    GET_SHIPMENT_DETAILS,
    {
      onCompleted: data => {
        if (data) {
          const {
            id,
            totalReceived,
            totalSent,
            totalBonusReceived,
            totalBonusSpent
          } = data?.getShipment?.user?.wallet;
          setShipmentDetails({
            ...data?.getShipment,
            shipmentCharge: data?.getShipment?.totalCharge,
            items: getItemNairaValue(data?.getShipment?.items)
          });
          saveUserInformation({
            topshipId: data?.getShipment?.user?.topshipId,
            id: data?.getShipment?.user?.id,
            fullName: data?.getShipment?.user?.fullName,
            phoneNumber: data?.getShipment?.user?.phoneNumber,
            email: data?.getShipment?.user?.email,
            wallet: {
              id: data?.getShipment?.user?.wallet?.id,
              totalReceived: data?.getShipment?.user?.wallet?.totalReceived,
              totalSent: data?.getShipment?.user?.wallet?.totalSent,
              totalBonusReceived:
                data?.getShipment?.user?.wallet?.totalBonusReceived,
              totalBonusSpent: data?.getShipment?.user?.wallet?.totalBonusSpent
            }
          });

          setWalletState({
            walletId: id,
            walletBalance: (totalReceived - totalSent) / 100,
            bonusBalance: (totalBonusReceived - totalBonusSpent) / 100
          });
          setShipmentType(
            getShipmentType(data?.getShipment?.itemCollectionMode)
          );
          setInsurancePlan(getInsurancePlan(data?.getShipment?.insuranceType));
          setCouponData({
            type: '',
            value: 0
          });
        }
      },
      onError: error => {
        NotificationManager.error(parseGraphqlError(error), 'Error');
      }
    }
  );

  const getShipmentDetailsQuery = (id: string) => {
    getShipmentDetails({
      variables: {
        shipmentId: id
      }
    });
  };

  return [getShipmentDetailsQuery, getShipmentDetailsQueryResult];
};

const useGetSavedAddresses = () => {
  return useQuery(GET_SAVED_ADDRESSES, {
    onCompleted: data => {}
  });
};

const useGetShipmentRateQuery = (OnCompleted?: (data: any) => void) => {
  const [, setCurrentRates] = useRecoilState(saveShipmentRateState);
  const [getRate, getShipmentRateResult] = useLazyQuery(GET_SHIPMENT_RATES, {
    onCompleted: data => {
      setCurrentRates(data.getAccurateShipmentRate);
      OnCompleted && OnCompleted(data);
    },
    onError: error => {
      // sendAlert(parseGraphqlError(error), 'Error');
      NotificationManager.error(
        'The city selected is currently not shipped to, you could choose another city of close proximity or contact support',
        'Error'
      );
    }
  });

  const getShipmentRates = (discount: boolean = false, shipmentDetail: any) => {
    getRate({
      variables: {
        discount,
        shipmentDetail
      }
    });
  };

  return [getShipmentRates, getShipmentRateResult];
};

const useGetLastMileRatesQuery = (OnCompleted?: (data: any) => void) => {
  const [, setLastMileRates] = useRecoilState(saveLastMileRateState);
  const [getLastMileRate, getLastMileRateResult] = useLazyQuery(
    GET_LAST_MILE_RATES,
    {
      onCompleted: data => {
        setLastMileRates(data.getLastMileRates);
        OnCompleted && OnCompleted(data);
      },
      onError: error => {
        NotificationManager.error('Delivery partner not available', 'Error');
      }
    }
  );

  const getLastMileRates = (input: any) => {
    getLastMileRate({
      variables: {
        input
      }
    });
  };

  return [getLastMileRates, getLastMileRateResult];
};

const useTrackShipmentQuery = ({
  onCompleted = (data: any) => {},
  onError = (error: ApolloError) => {}
}) => {
  const [trackShipment, trackShipmentResult] = useLazyQuery(TRACK_SHIPMENT, {
    onCompleted: data => {
      onCompleted?.(data);
    },
    onError: error => {
      NotificationManager.error(parseGraphqlError(error), 'Error');

      onError?.(error);
    },
    fetchPolicy: 'no-cache'
  });

  const getTrackingDetails = (trackingId: string) => {
    trackShipment({
      variables: {
        trackingId
      }
    });
  };

  return [getTrackingDetails, trackShipmentResult];
};

// const useTrackShipmentQuery = (trackingId: string) => {
//   return useQuery(TRACK_SHIPMENT, {
//     variables: {
//       trackingId
//     },
//     onError: error => {
//       NotificationManager.error(parseGraphqlError(error), 'Error');
//     }
//   });
// };

const useGetWithdrawalRequestQuery = () => {
  const client = useApolloClient();
  const [, setWithdrawalRequestNumber] = useRecoilState(
    withdrawalRequestNumberState
  );

  const getWithdrawalRequestQuery = async (
    page: number,
    take: number,
    search: string
  ) => {
    const { data } = await client.query({
      query: GET_WITHDRAWAL_REQUESTS,
      variables: {
        filter: {
          page,
          take,
          keyword: search
        }
      },
      fetchPolicy: 'no-cache'
    });

    setWithdrawalRequestNumber(data?.getAllWithdrawalRequests?.totalCount);
    return data;
  };

  return getWithdrawalRequestQuery;
};

const useGetCountriesQuery = () => {
  return useQuery(GET_COUNTRIES, {
    onCompleted: data => {},
    onError: error =>
      NotificationManager.error(parseGraphqlError(error), 'Error')
  });
};

const useGetStatesQuery = () => {
  const [getStates, statesResult] = useLazyQuery(GET_STATES, {
    onError: error =>
      NotificationManager.error(parseGraphqlError(error), 'Error'),
    fetchPolicy: 'cache-and-network'
  });

  const getStatesList = (countryCode: string) => {
    getStates({ variables: { countryCode } });
  };

  return [getStatesList, statesResult];
};

const useGetCitiesQuery = () => {
  const [getCities, citiesResult] = useLazyQuery(GET_CITIES, {
    onError: error =>
      NotificationManager.error(parseGraphqlError(error), 'Error')
  });

  const getCitiesList = (countryCode: string) => {
    getCities({ variables: { countryCode } });
  };

  return [getCitiesList, citiesResult];
};

const useGetConfigurationQuery = () => {
  const [, setConfigurationState] = useRecoilState(saveConfigurationState);

  return useQuery(GET_CONFIG, {
    onCompleted: data => {
      if (data) {
        const {
          commissionPercent,
          paystackCommissionPercent,
          minimumWithdrawal,
          referralBonus,
          autoApproveWithdrawal,
          dhlShippingDate,
          discount
        } = data.getConfiguration;

        setConfigurationState({
          commissionPercent,
          paystackCommissionPercent,
          minimumWithdrawal: minimumWithdrawal / 100,
          autoApproveWithdrawal,
          dhlShippingDate,
          referralBonus,
          discount
        });
      }
    },
    onError: error =>
      NotificationManager.error(parseGraphqlError(error), 'Error')
  });
};

const useGetCouponQuery = (onCompleted?: (data: any) => void) => {
  const [getCoupon, couponResult] = useLazyQuery(GET_COUPON, {
    onError: error => {
      if (error && couponResult?.variables?.code !== 'RefetchCouponCode') {
        NotificationManager.error(parseGraphqlError(error), 'Error');
      }
    },

    onCompleted: data => {
      onCompleted && onCompleted(data);
    }
  });

  const getCouponValue = (code: string) => {
    getCoupon({ variables: { code } });
  };

  return [getCouponValue, couponResult];
};

const useGetDHLCitiesQuery = () => {
  const {
    mutate: getCities,
    isLoading,
    isError,
    data
  } = useReactQueryMutation(getDhlCities, {
    onError: error =>
      NotificationManager.error(parseGraphqlError(error), 'Error')
  });
  const getCitiesMutationResult = {
    loading: isLoading,
    error: isError,
    data
  };

  return [getCities, getCitiesMutationResult];
};

const useGetShipmentRateQueryMultiple = (values: IShipment) => {
  const allRates: any[] = [];
  const client = useApolloClient();
  const [, setShipmentData] = useRecoilState(saveShipmentDetailsState);
  const [, setMultipleShipmentRates] = useRecoilState(
    multipleShipmentRatesState
  );
  const [multipleRateLoading, setMultipleRateLoading] = useRecoilState(
    multipleRateLoadingState
  );
  const { senderDetail: sender } = values;
  const getShipmentRates = async (shipmentDetail: any) => {
    const { data, error } = await client.query({
      query: GET_SHIPMENT_RATES,
      variables: {
        shipmentDetail
      },
      fetchPolicy: 'no-cache'
    });

    if (error) {
      NotificationManager.error(parseGraphqlError(error), 'error');
      setMultipleRateLoading(false);
    }
    return data;
  };

  const handleRateSelection = (rate: any, index: number) => {
    const updatedValue = {
      ...values.multipleReceivers[index],
      pricingTier: shipmentStatusDictionary(rate?.mode),
      shipmentCharge: Math.round(rate?.cost)
    };
    setShipmentData({
      ...values,
      multipleReceivers: values.multipleReceivers.map((value, valueIndex) =>
        valueIndex === index ? updatedValue : value
      )
    });
  };

  const getMultipleShipmentRates = async () => {
    setMultipleRateLoading(true);

    return await Promise.all(
      values?.multipleReceivers.map(async receiver => {
        const totalWeight = receiver.items.reduce(
          (a: any, b: any) => a + b?.weight,
          0
        );
        const totalValue = receiver.items.reduce(
          (a: any, b: any) => a + b?.value,
          0
        );
        const requestRatesData = {
          senderDetails: {
            cityName: sender?.city,
            countryCode: sender?.countryCode,
            postalCode: sender?.postalCode ?? ''
          },
          receiverDetails: {
            cityName: receiver?.receiverDetail?.city,
            countryCode: receiver?.receiverDetail?.countryCode,
            postalCode: receiver?.receiverDetail?.postalCode ?? ''
          },
          totalWeight: parseFloat(totalWeight),
          totalValue: parseFloat(totalValue) ?? 1
        };

        return await getShipmentRates(requestRatesData).catch(err => {
          setMultipleRateLoading(false);
          NotificationManager.error(parseGraphqlError(err), 'Error');
        });
      })
    ).then(response => {
      const formattedResponse = response.map((res, idx) => {
        return {
          index: idx,
          list: res?.getAccurateShipmentRate ? res?.getAccurateShipmentRate : []
        };
      });

      formattedResponse.forEach((rate, rateIndex) => {
        const shipmentRatesLessThanTenKg = rate?.list?.filter(
          (rateItem: any) => !rateItem?.mode.includes('Cargo')
        );

        const totalWeight = values.multipleReceivers[rateIndex]?.items?.reduce(
          (a: any, b: any) => a + b?.weight,
          0
        );

        if (
          rate.list.length === 1 &&
          (!values.multipleReceivers[rateIndex]?.pricingTier ||
            values.multipleReceivers[rateIndex]?.pricingTier === '')
        ) {
          handleRateSelection(rate.list[0], rateIndex);
        }

        if (totalWeight < 10) {
          allRates.push({ ...rate, list: shipmentRatesLessThanTenKg });
        } else {
          allRates.push(rate);
        }
      });

      setMultipleRateLoading(false);
      setMultipleShipmentRates(allRates as never[]);
      return response;
    });
  };

  return [getMultipleShipmentRates, { loading: multipleRateLoading }];
};

const useGetUserLazyQuery = (setInitialValues?: any, type?: any) => {
  const userPayload = useGetUserPayload() as any;
  const [shipmentDetails, setShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  const selectedOption = shipmentOptions.find(
    option => shipmentDetails?.shipmentRoute === option.value
  );
  const [getUser, getUserResult] = useLazyQuery(GET_USER, {
    onCompleted: data => {
      const { fullName, email, phoneNumber } = data.user;
      const isImport =
        selectedOption?.id === 2 &&
        !shipmentDetails?.receiverDetail?.name &&
        type &&
        type === 'receiver';
      const isExport =
        selectedOption?.id === 1 &&
        !shipmentDetails?.senderDetail?.name &&
        type &&
        type === 'sender';
      if (isImport || isExport) {
        setInitialValues &&
          setInitialValues((values: any) => ({
            ...values,
            name: fullName,
            email,
            phoneNumber
          }));
      }
      !setInitialValues &&
        setShipmentDetail({
          ...shipmentDetails,
          [selectedOption?.id === 2 ? 'receiverDetail' : 'senderDetail']: {
            ...shipmentDetails[
              selectedOption?.id === 2 ? 'receiverDetail' : 'senderDetail'
            ],
            name: fullName,
            email,
            phoneNumber
          }
        });
    },
    fetchPolicy: 'no-cache'
  });

  const getUserDetails = () => {
    getUser({ variables: { sub: userPayload?.sub } });
  };

  return [getUserDetails, getUserResult];
};

const useGetShopnshipCurrencyRates = () => {
  const [, setShopnshipRate] = useRecoilState(shopnshipRateState);
  const {
    mutateAsync: getShopnshipCurrencyRates,
    ...getShopnshipCurrencyRatesResult
  } = useMutation(getShopnshipCurrencyRate, {
    onSuccess: (data: any) => {
      setShopnshipRate(data.data);
    }
  });

  return [getShopnshipCurrencyRates, getShopnshipCurrencyRatesResult];
};

const useGetPickupRates = (onCompleted?: (data: any) => void) => {
  const [getRates, getPickupRatesResult] = useLazyQuery(GET_PICKUP_RATES, {
    onCompleted: data => {
      onCompleted?.(data);
    }
  });

  const getPickupRates = (input: PickupRatesInput) => {
    return getRates({
      variables: { input }
    });
  };

  return [getPickupRates, getPickupRatesResult];
};

export {
  useGetWalletBalanceQuery,
  useGenerateTransactionReferenceQuery,
  useGetUserQuery,
  useGetUsersQuery,
  useGetWalletQuery,
  useGetWithdrawalRequestQuery,
  useGetShipmentsQuery,
  useGetShipmentDetailsQuery,
  useGetShipmentMetricQuery,
  useGetSavedAddresses,
  useGetShipmentRateQuery,
  useGetUserLazyQuery,
  useGetShopNShipsQuery,
  useGetOneShopnshipQuery,
  // useTrackShipmentLazyQuery,
  useTrackShipmentQuery,
  useGetShopnshipCurrencyRates,
  useGetTransactionsQuery,
  useGetUserInformationQuery,
  useGetPaystackMessagesQuery,
  useGetConfigurationQuery,
  useGetCountriesQuery,
  useGetStatesQuery,
  useGetCitiesQuery,
  useGetFeedbackQuery,
  useGetCouponQuery,
  useGetDHLCitiesQuery,
  useGetShipmentRateQueryMultiple,
  useGetUnverifiedBusinesses,
  useGetPickupRates,
  useGetTicketsQuery,
  useGetLastMileRatesQuery
};
