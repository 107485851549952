import React, { MutableRefObject, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DialogShell } from 'components';
import {
  useCreateUserMutation,
  useCreateTopshipAdminMutation
} from 'operations/mutations';
import { UserTypeEnum } from 'enums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: theme.spacing(2)
    },
    formLabel: {
      fontWeight: 600
    },
    formError: {
      textAlign: 'start'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    formContainer: {
      textAlign: 'center'
    },
    formHelper: {
      color: 'red'
    },
    success: {
      marginTop: '20px',
      marginBottom: '60px',
      textAlign: 'center'
    },
    message: {
      fontWeight: 500
    }
  })
);

type Props = {
  handleClose: () => void;
  open: boolean;
  reset: boolean;
  setReset: (reset: boolean) => void;
  tableRef: MutableRefObject<any>;
};

const CreateUserDialog = ({
  handleClose,
  open,
  reset,
  setReset,
  tableRef
}: Props) => {
  const classes = useStyles();
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const createUserMutation = useCreateUserMutation();
  const createAdminMutation = useCreateTopshipAdminMutation();

  const userType = localStorage.getItem('type');
  const isSuperAdmin = userType === UserTypeEnum.SUPERADMIN;

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('What is your email address?'),
    phoneNumber: yup.string().required(`Please enter user's phone number`),
    fullName: yup.string().required(`Please enter user's fullname`)
  });

  const adminValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('What is your email address?'),
    phoneNumber: yup.string().required(`Please enter user's phone number`),
    fullName: yup.string().required(`Please enter user's fullname`),
    adminType: yup.string().required(`Please select user type`)
  });

  const { errors, values, touched, handleSubmit, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      phoneNumber: '',
      fullName: '',
      adminType: ''
    },
    validationSchema: isSuperAdmin ? adminValidationSchema : validationSchema,
    onSubmit: async values => {
      setLoading(true);
      let result = null;

      if (!values.adminType || values.adminType === UserTypeEnum.USER) {
        const data = {
          email: values.email,
          phoneNumber: values.phoneNumber,
          fullName: values.fullName
        };

        result = await createUserMutation?.(data);
      } else {
        // create admin
        result = await createAdminMutation?.(values);
      }

      if (result) {
        if (tableRef) {
          tableRef.current && tableRef.current.onQueryChange();
        }

        setReset(false);
        setSuccess(true);
        setLoading(false);
      } else {
        setSuccess(false);
        setLoading(false);
      }
    }
  });

  return (
    <div>
      <DialogShell
        actionButtonText='Create'
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        hideActionButton={!reset && success}
        loading={loading}
        open={open}
        title='Create New User'
      >
        <form>
          {!reset && success ? (
            <div className={classes.success}>
              <img
                width='40%'
                src='/static/images/svg/checkmark.svg'
                alt='checkmark'
              />

              <Typography className={classes.message}>
                New User "{values.fullName}" has been created
              </Typography>
            </div>
          ) : (
            <div className={classes.formContainer}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel className={classes.formLabel} htmlFor='fullName'>
                  Merchant Name
                </InputLabel>
                <Input
                  id='fullName'
                  type='fullName'
                  name='fullName'
                  value={values.fullName}
                  onChange={handleChange}
                  error={Boolean(touched.fullName && errors.fullName)}
                  fullWidth
                  startAdornment={
                    <InputAdornment position='start'>
                      <img src='/static/images/svg/user.svg' alt='user' />
                    </InputAdornment>
                  }
                />

                <small className={classes.formError}>
                  {touched.fullName && errors.fullName}
                </small>
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <InputLabel className={classes.formLabel} htmlFor='email'>
                  Email Address
                </InputLabel>
                <Input
                  id='email'
                  type='email'
                  name='email'
                  value={values.email}
                  onChange={handleChange}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  startAdornment={
                    <InputAdornment position='start'>
                      <img src='/static/images/svg/Envelope.svg' alt='mail' />
                    </InputAdornment>
                  }
                />

                <small className={classes.formError}>
                  {touched.email && errors.email}
                </small>
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <InputLabel className={classes.formLabel} htmlFor='phoneNumber'>
                  Phone No
                </InputLabel>
                <Input
                  id='phoneNumber'
                  type='phoneNumber'
                  name='phoneNumber'
                  value={values.phoneNumber}
                  onChange={handleChange}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  fullWidth
                  startAdornment={
                    <InputAdornment position='start'>
                      <img src='/static/images/svg/PhoneCall.svg' alt='phone' />
                    </InputAdornment>
                  }
                />

                <small className={classes.formError}>
                  {touched.phoneNumber && errors.phoneNumber}
                </small>
              </FormControl>

              {userType === UserTypeEnum.SUPERADMIN ? (
                <FormControl className={classes.formControl} fullWidth>
                  <Select
                    id='adminType'
                    name='adminType'
                    value={values.adminType}
                    onChange={handleChange}
                    error={Boolean(touched.adminType && errors.adminType)}
                    displayEmpty
                    style={{ textAlign: 'left' }}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem className={classes.formLabel} value='' disabled>
                      User Type
                    </MenuItem>

                    {['Admin', 'SuperAdmin', 'User'].map(item => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>

                  <FormHelperText className={classes.formHelper}>
                    {touched.adminType && errors.adminType}
                  </FormHelperText>
                </FormControl>
              ) : null}
            </div>
          )}
        </form>
      </DialogShell>
    </div>
  );
};

export default CreateUserDialog;
