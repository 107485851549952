import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { DialogShell } from 'components';
import { useUpdateUserMutation } from 'operations/mutations';

type Props = {
  customerName: string;
  topshipId: string;
  handleClose: () => void;
  open: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  title: {
    fontSize: '18px',
    fontWeight: 700
  },
  form: {
    padding: '0px 10px 10px 10px'
  },
  formContent: {
    marginTop: theme.spacing(4)
  },
  formContent2: {
    marginTop: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(6)
  },
  total: {
    color: '#43d882'
  },
  success: {
    marginTop: '20px',
    marginBottom: '60px',
    textAlign: 'center'
  },
  message: {
    fontWeight: 500
  }
}));

const AddDiscountDialog = ({
  customerName,
  handleClose,
  open,
  topshipId
}: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);

  const validationSchema = yup.object().shape({
    discount: yup.number().required(`Please enter amount to top up`)
  });

  const updateUserMutation = useUpdateUserMutation();

  const { errors, values, touched, handleSubmit, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      discount: ''
    },
    validationSchema,
    onSubmit: async values => {
      setLoading(true);

      await updateUserMutation(
        {
          percentageCommissionOnUser: Number(values.discount)
        },
        topshipId
      );

      window.location.reload();
      setLoading(false);
    }
  });

  return (
    <div>
      <DialogShell
        actionButtonText='Add Discount'
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        hideActionButton={false}
        loading={loading}
        open={open}
        title=''
      >
        <form className={classes.form}>
          <div>
            <Typography className={classes.title}>
              {' '}
              Discount for customer
            </Typography>
            <Typography variant='subtitle1' color='primary'>
              {customerName}
            </Typography>
          </div>

          <Divider variant='fullWidth' />

          <FormControl className={classes.formContent} fullWidth>
            <InputLabel className='form-label' htmlFor='discount'>
              Discount
            </InputLabel>

            <Input
              id='discount'
              type='number'
              name='discount'
              value={values.discount}
              onChange={handleChange}
              error={Boolean(touched.discount && errors.discount)}
              fullWidth
              startAdornment={
                <InputAdornment position='start'>
                  <AccountBalanceIcon color='primary' />
                </InputAdornment>
              }
            />

            <small>{touched.discount && errors.discount}</small>
          </FormControl>
        </form>
      </DialogShell>
    </div>
  );
};

export default AddDiscountDialog;
