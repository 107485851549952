import React from 'react';

type Props = {
  active: boolean;
  cost: string;
  onClick: () => void;
  list: any;
  index: number;
};

const RequestOption = ({ active, cost, onClick, list, index }: Props) => {
  return (
    <div
      className={`quote-summary__rates ${
        active && 'quote-summary__rates--selected'
      }`}
      key={index}
      onClick={onClick}
    >
      <div className='quote-summary__rates__top'>
        <figure>
          <img
            src={
              active
                ? '/static/images/svg/checked.svg'
                : '/static/images/svg/checkbox.svg'
            }
            alt='check box'
          />
        </figure>

        <span className={active ? 'selected' : ''}>{cost}</span>
      </div>
      <div className='quote-summary__rates__bottom'>{list}</div>
    </div>
  );
};

export default RequestOption;
