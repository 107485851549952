import React from 'react';
import Toc from '@material-ui/icons/Toc';
import Person from '@material-ui/icons/Person';

export const shopNShipLinks = [
  {
    name: 'Personal Information',
    link: '/shop-and-ship-personal-information',
    icon: (
      <div className='two-icons'>
        <Person />
        <svg
          className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv'
          focusable='false'
          aria-hidden='true'
          viewBox='0 0 24 24'
          data-testid='NorthEastIcon'
        >
          <path d='M9 5v2h6.59L4 18.59 5.41 20 17 8.41V15h2V5H9z'></path>
        </svg>
      </div>
    )
  },
  {
    name: 'Package Information',
    link: '/shop-and-ship-package-information',
    icon: (
      <div className='two-icons'>
        <svg
          className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv'
          focusable='false'
          aria-hidden='true'
          viewBox='0 0 24 24'
          data-testid='InventoryIcon'
        >
          <path d='M20 2H4c-1 0-2 .9-2 2v3.01c0 .72.43 1.34 1 1.69V20c0 1.1 1.1 2 2 2h14c.9 0 2-.9 2-2V8.7c.57-.35 1-.97 1-1.69V4c0-1.1-1-2-2-2zm-5 12H9v-2h6v2zm5-7H4V4l16-.02V7z'></path>
        </svg>
        <svg
          className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv'
          focusable='false'
          aria-hidden='true'
          viewBox='0 0 24 24'
          data-testid='NorthEastIcon'
        >
          <path d='M9 5v2h6.59L4 18.59 5.41 20 17 8.41V15h2V5H9z'></path>
        </svg>
      </div>
    )
  },
  {
    name: 'Item Information',
    link: '/shop-and-ship-item-information',
    icon: (
      <div className='two-icons'>
        <Toc />
        <svg
          className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv'
          focusable='false'
          aria-hidden='true'
          viewBox='0 0 24 24'
          data-testid='NorthEastIcon'
        >
          <path d='M9 5v2h6.59L4 18.59 5.41 20 17 8.41V15h2V5H9z'></path>
        </svg>
      </div>
    )
  },
  {
    name: 'Other Information',
    link: '/shop-and-ship-other-information',
    icon: (
      <div className='two-icons'>
        <svg
          className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv'
          focusable='false'
          aria-hidden='true'
          viewBox='0 0 24 24'
          data-testid='FeedSharpIcon'
        >
          <path d='M16 3H3v18h18V8l-5-5zM7 7h5v2H7V7zm10 10H7v-2h10v2zm0-4H7v-2h10v2zm-2-4V5l4 4h-4z'></path>
        </svg>
        <svg
          className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv'
          focusable='false'
          aria-hidden='true'
          viewBox='0 0 24 24'
          data-testid='NorthEastIcon'
        >
          <path d='M9 5v2h6.59L4 18.59 5.41 20 17 8.41V15h2V5H9z'></path>
        </svg>
      </div>
    )
  },
  {
    name: 'Shop & Ship Summary',
    link: '/shop-and-ship-summary',
    icon: (
      <div className='two-icons'>
        <svg
          className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv'
          focusable='false'
          aria-hidden='true'
          viewBox='0 0 24 24'
          data-testid='SummarizeIcon'
        >
          <path d='M15 3H5c-1.1 0-1.99.9-1.99 2L3 19c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V9l-6-6zM8 17c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm0-4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm0-4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm6 1V4.5l5.5 5.5H14z'></path>
        </svg>
      </div>
    )
  },
  {
    name: 'Shop & Ship Payment',
    link: '/shop-and-ship-payment/',
    icon: (
      <div className='two-icons'>
        <svg
          className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv'
          focusable='false'
          aria-hidden='true'
          viewBox='0 0 24 24'
          data-testid='PaymentIcon'
        >
          <path d='M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z'></path>
        </svg>
      </div>
    )
  }
];
