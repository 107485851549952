export enum UserMenuOptionsEnum {
  ADD_DISCOUNT = 'Add Discount',
  ADD_TICKET = 'Add Ticket',
  BOOK_EXPORT = 'Book Export',
  BOOK_MULTIPLE_EXPORT = 'Book Multiple Export',
  BOOK_IMPORT = 'Book Import',
  BOOK_SHIPMENT = 'Book Shipment',
  BOOK_SHOPNSHIP = 'Book Shop n Ship',
  DELETE = 'Delete',
  MANAGE = 'Manage',
  ADDITIONAL_CHARGE = 'Additional Charge',
  VERIFY = 'Verify',
  UNVERIFY = 'Unverify'
}

export enum BusinessMenuOptionsEnum {
  MANAGE = 'Manage',
  VERIFY = 'Verify'
}
