import React, { Suspense } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
// @ts-ignore
import { NotificationContainer } from 'react-notifications';
import PageRoutes from './shared/page-routes';
import ROUTES from 'shared/routes';
import { Loader, Restricted } from 'components';
import { LocationState } from 'types';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';

const App = () => {
  const location = useLocation<LocationState>();
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <Switch>
          {PageRoutes.map(
            ({
              exact,
              path,
              component: Component,
              restrictAccess,
              key,
              ...rest
            }) => {
              const componentKey = key || path;
              return restrictAccess ? (
                <Restricted
                  {...rest}
                  key={componentKey}
                  exact={exact}
                  path={path}
                  component={Component}
                  restrictAccess={restrictAccess}
                />
              ) : location.pathname === '/' ? (
                (window.location.href = ROUTES.LOGIN)
              ) : (
                <Route
                  key={componentKey}
                  exact={exact}
                  path={path}
                  render={props => (
                    <Suspense fallback={<Loader />}>
                      <Component {...props} key={componentKey} />
                    </Suspense>
                  )}
                />
              );
            }
          )}
        </Switch>

        <NotificationContainer />
      </RecoilRoot>
    </QueryClientProvider>
  );
};

export default App;
