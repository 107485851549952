const pickupStatuses = [
  'AwaitingPickUp',
  'InTransit',
  'PickupCancelled',
  'PickupDispatched',
  'SuccessfullyPicked',
  'PickupCancelledByCourier',
  'PickupCancelledByMerchant',
  'PickupCancelledInternally',
  'PickupRescheduledByMerchant',
  'PendingConfirmation',
  'AssignedForDelivery',
  'PickupInProgress',
  'RiderAssigned',
  'PickupFailed'
];

export { pickupStatuses };
