import * as jwt from 'jsonwebtoken';

/**
 * scrambles string data
 * @param {String} token - input string data
 * @returns {String} - scrambled data
 */
const shuffleToken = (token: string) => token.split('').reverse().join('');

const secret = process.env.REACT_APP_JWT_SECRET as string;

const verifyToken = (token: string) => {
  if (!token)
    return {
      Error: 'Failed to authenticate token',
      success: false
    };
  const reshuffledToken = shuffleToken(token);
  let output = {};
  return jwt.verify(reshuffledToken, secret, (err: any, decoded: any) => {
    if (err) {
      output = {
        Error: 'Failed to authenticate token',
        success: false
      };
    } else {
      localStorage.setItem('fullName', decoded?.fullName);
      output = { success: true, ...decoded };
    }
    return output;
  });
};

export { verifyToken, shuffleToken };
