import { lazy } from 'react';

import { PageRoute } from 'types';
import ROUTES from './routes';

const Book = lazy(() => import(/* webpackChunkName: "login" */ 'pages/book'));
const MultipleBook = lazy(
  () => import(/* webpackChunkName: "login" */ 'pages/multiple-book')
);
const Dashboard = lazy(
  () => import(/* webpackChunkName: "login" */ 'pages/dashboard')
);
const Imports = lazy(
  () => import(/* webpackChunkName: "login" */ 'pages/imports')
);
const Login = lazy(() => import(/* webpackChunkName: "login" */ 'pages/login'));
const UserInformation = lazy(
  () =>
    import(/* webpackChunkName: "user-information" */ 'pages/user-information')
);
const ShopNShipPersonalInfo = lazy(
  () =>
    import(
      /* webpackChunkName: "shopNShipPersonalInfo" */ 'pages/book-shopnship/shop-n-ship-personal-information'
    )
);
const ShopNShipPackageInfo = lazy(
  () =>
    import(
      /* webpackChunkName: "shopNShipPackageInfo" */ 'pages/book-shopnship/shop-n-ship-package-information'
    )
);
const ShopNShipItemInfo = lazy(
  () =>
    import(
      /* webpackChunkName: "shopNShipItemInfo" */ 'pages/book-shopnship/shop-n-ship-item-information'
    )
);
const ShopNShipOtherInfo = lazy(
  () =>
    import(
      /* webpackChunkName: "shopNShipOtherInfo" */ 'pages/book-shopnship/shop-n-ship-other-information'
    )
);
const ShopNShipSummary = lazy(
  () =>
    import(
      /* webpackChunkName: "shopNShipOtherInfo" */ 'pages/book-shopnship/shop-n-ship-summary'
    )
);
const ShopNShipPayment = lazy(
  () =>
    import(
      /* webpackChunkName: "shopNShipOtherInfo" */ 'pages/book-shopnship/shop-n-ship-summary'
    )
);

const ShipmentDocuments = lazy(
  () =>
    import(
      /* webpackChunkName: "shipment-documents" */
      'pages/shipment-documents'
    )
);

const AddTicket = lazy(
  () =>
    import(
      /* webpackChunkName: "add-ticket" */
      'pages/add-ticket'
    )
);

const PageRoutes: PageRoute[] = [
  {
    component: Book,
    exact: true,
    key: 'book',
    path: ROUTES.EXPORT,
    restrictAccess: true
  },
  {
    component: MultipleBook,
    exact: true,
    key: 'muliple-book',
    path: ROUTES.MULTIPLE_EXPORT,
    restrictAccess: true
  },
  {
    component: ShopNShipPersonalInfo,
    exact: true,
    key: 'shopNShipPersonalInfo',
    path: ROUTES.SHOPNSHIP_PERSONAL_INFO,
    restrictAccess: true
  },
  {
    component: ShopNShipPackageInfo,
    exact: true,
    key: 'shopNShipPackageInfo',
    path: ROUTES.SHOPNSHIP_PACKAGE_INFO,
    restrictAccess: true
  },
  {
    component: ShopNShipItemInfo,
    exact: true,
    key: 'shopNShipItemInfo',
    path: ROUTES.SHOPNSHIP_ITEM_INFO,
    restrictAccess: true
  },
  {
    component: ShopNShipOtherInfo,
    exact: true,
    key: 'shopNShipOtherInfo',
    path: ROUTES.SHOPNSHIP_OTHER_INFO,
    restrictAccess: true
  },
  {
    component: ShopNShipSummary,
    exact: true,
    key: 'shopNShipSummary',
    path: ROUTES.SHOPNSHIP_SUMMARY,
    restrictAccess: true
  },
  {
    component: ShopNShipPayment,
    exact: true,
    key: 'shopNShipPayment',
    path: ROUTES.SHOPNSHIP_PAYMENT,
    restrictAccess: true
  },
  {
    component: Dashboard,
    exact: true,
    key: 'dashboard',
    path: ROUTES.DASHBOARD,
    restrictAccess: true
  },
  {
    component: Imports,
    exact: true,
    key: 'import',
    path: ROUTES.IMPORT,
    restrictAccess: true
  },
  {
    component: Login,
    key: 'login',
    path: ROUTES.LOGIN
  },
  {
    component: UserInformation,
    exact: true,
    key: 'user-information',
    path: ROUTES.USER_INFORMATION,
    restrictAccess: true
  },
  {
    component: AddTicket,
    exact: true,
    key: 'add-ticket',
    path: ROUTES.ADD_TICKET,
    restrictAccess: true
  },
  {
    component: ShipmentDocuments,
    exact: true,
    key: 'shipment-documents',
    path: ROUTES.SHIPMENT_DOCUMENTS,
    restrictAccess: true
  }
];

export default PageRoutes;
