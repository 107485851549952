const importShipmentTypes = [
  {
    name: 'Drop-off',
    summary: 'Drop-off your items at the nearest Topship processing center',
    cost: 'FREE',
    value: 'DropOff',
    amount: 0.0
  }
];

export { importShipmentTypes };
