const routes = {
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  USER_INFORMATION: '/user-information/:id',
  ADD_TICKET: '/add-ticket',
  SHIPMENT_DOCUMENTS: '/shipment-documents/:id',
  EXPORT: '/export/:type/:id',
  MULTIPLE_EXPORT: '/multiple-export/create/:id',
  IMPORT: '/import/:type/:id',
  SHOPNSHIP_PERSONAL_INFO: '/shop-and-ship-personal-information',
  SHOPNSHIP_PACKAGE_INFO: '/shop-and-ship-package-information',
  SHOPNSHIP_ITEM_INFO: '/shop-and-ship-item-information',
  SHOPNSHIP_OTHER_INFO: '/shop-and-ship-other-information',
  SHOPNSHIP_SUMMARY: '/shop-and-ship-summary',
  SHOPNSHIP_PAYMENT: '/shop-and-ship-payment'
};

export default routes;
