export enum PickupStatusEnum {
  AWAITING_PICK_UP = 'AwaitingPickUp',
  IN_TRANSIT = 'InTransit',
  PICK_UP_CANCELLED = 'PickupCancelled',
  PICK_UP_DISPATCHED = 'PickupDispatched',
  SUCCESSFULLY_PICKED = 'SuccessfullyPicked',
  PICK_UP_CANCELLED_BY_COURIER = 'PickupCancelledByCourier',
  PICK_UP_CANCELLED_BY_MERCHANT = 'PickupCancelledByMerchant',
  PICK_UP_CANCELLED_INTERNALLY = 'PickupCancelledInternally',
  PICK_UP_RESCHEDULED_BY_MERCHANT = 'PickupRescheduledByMerchant',
  ASSIGNED_FOR_DELIVERY = 'AssignedForDelivery',
  PENDING_CONFIRMATION = 'PendingConfirmation'
}
