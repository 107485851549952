import React from 'react';
import CustomSpinner from './../CustomSpinner/custom-spinner';
import { useRecoilState } from 'recoil';
import { busyOverlayState } from 'recoil-data/atoms';

type Props = {
  loading: boolean;
  text?: string;
  speed?: string;
};

const BusyOverlay = ({ loading, text, speed }: Props) => {
  const [isBusy] = useRecoilState(busyOverlayState);

  return (
    <div className={loading || isBusy ? 'busy-overlay--show' : 'busy-overlay'}>
      <CustomSpinner
        text={text}
        textSize='1.5rem'
        textColor='var(--color-primary)'
        size='4rem'
        thickness='.6rem'
        background='#e6e3df'
        spinColor='var(--color-blue-dark)'
        speed={speed || '.8s'}
      />
    </div>
  );
};

export default BusyOverlay;
