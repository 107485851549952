import { ItemsType } from 'types';

export const getItemKoboValue = (items: ItemsType[]) => {
  return items?.map(item => {
    const value = (Number(item.value) * 100) as any;
    const newItem = { ...item, value };
    return newItem;
  });
};

export const getItemNairaValue = (items: ItemsType[]) => {
  return items?.map(item => {
    const value = (Number(item.value) / 100) as any;
    const newItem = { ...item, value };
    return newItem;
  });
};
