export const shippingRates = [
  {
    name: 'Express ',
    pickupDeliveryTime: 'Delivery in 3-5 working days',
    cost: '₦50,000',
    value: 'Express',
    amount: 50000
  },
  {
    name: 'Standard ',
    // pickupLeadTime: ' 24-48hrs',
    pickupDeliveryTime: 'Delivery in 5-7 working days',
    cost: '₦20,850',
    value: 'Standard',
    amount: 20850
  },
  {
    name: 'Cargo ',
    // pickupLeadTime: ' 12-24hrs',
    pickupDeliveryTime: 'Cargo delivery in 7-14 working days',
    cost: '₦50,000',
    value: 'Budget',
    amount: 50000
  },
  {
    name: 'Regular',
    // pickupLeadTime: ' 12-24hrs',
    pickupDeliveryTime: 'Cargo delivery in 7-14 working days',
    cost: '₦50,000',
    value: 'Regular',
    amount: 50000
  },
  {
    name: 'Doorstep Cargo',
    // pickupLeadTime: ' 12-24hrs',
    pickupDeliveryTime: 'Cargo delivery in 7-14 working days',
    cost: '₦50,000',
    value: 'LastMileBudget',
    amount: 50000
  },
  {
    name: 'Saver',
    pickupDeliveryTime: 'Delivery in 7-10 working days',
    cost: '₦20,850',
    value: 'UPSExpedited',
    amount: 20850
  },
  {
    name: 'Saver',
    pickupDeliveryTime: 'Delivery in 7-10 working days',
    cost: '₦20,850',
    value: 'UPSEconomy',
    amount: 20850
  },
  {
    name: 'Saver',
    pickupDeliveryTime: 'Delivery in 7-10 working days',
    cost: '₦20,850',
    value: 'UPSExpress',
    amount: 20850
  },
  {
    name: 'Saver Priority',
    pickupDeliveryTime: 'Delivery in 10-12 working days',
    cost: '₦20,850',
    value: 'SaverPriority',
    amount: 20850
  },
  {
    name: 'Saver',
    pickupDeliveryTime: 'Delivery in 10-12 working days',
    cost: '₦20,850',
    value: 'FedEx',
    amount: 20850
  },
  {
    name: 'Premium ',
    pickupDeliveryTime: 'Delivery in 3-5 working days',
    cost: '₦20,850',
    value: 'Premium',
    amount: 20850
  },
  {
    name: 'Saver Domestic',
    value: 'GIG',
    pickupDeliveryTime: 'Delivery in 3 - 5 working days',
    cost: '₦50,000',
    amount: 50000
  },
  {
    name: 'Ship Via Sea',
    pickupDeliveryTime: 'Delivery in 8 - 12 weeks',
    cost: '₦20,850',
    value: 'SeaExport',
    pricingTier: 'SeaExport',
    amount: 20850
  },
  {
    name: 'Sea Import',
    pickupDeliveryTime: 'Delivery in 8 - 12 weeks',
    cost: '₦20,850',
    value: 'SeaImport',
    amount: 20850
  }
];

export const lastMileShippingRates = [
  {
    name: 'Saver Domestic',
    value: 'GIG',
    pickupDeliveryTime: 'Delivery in 3 - 5 working days',
    cost: '₦50,000',
    amount: 50000
  },
  {
    name: 'DHL',
    pickupDeliveryTime: 'Delivery in 3 - 5 working days',
    cost: '₦50,000',
    value: 'Express',
    amount: 50000
  }
];

export const statesWithTopshipHubs = ['Lagos', 'Abuja', 'Oyo', 'Rivers'];
