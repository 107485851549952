import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ChatIcon from '@material-ui/icons/Chat';
import { toCurrency } from 'helpers';
import { DialogShell } from 'components';
import { useUpdateWalletBalanceMutation } from 'operations/mutations';
import { walletTransactionTypesEnum } from 'enums';
import numberWithCommas from 'helpers/numberWithComma';

type Props = {
  customerName: string;
  handleClose: () => void;
  open: boolean;
  reset: boolean;
  setReset: (reset: boolean) => void;
  walletBalance: number;
  walletId: string;
  withdrawalAmount?: number;
  withdrawFromWallet?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  title: {
    fontSize: '18px',
    fontWeight: 700
  },
  form: {
    padding: '0px 10px 10px 10px'
  },
  formContent: {
    marginTop: theme.spacing(4)
  },
  formContent2: {
    marginTop: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(6)
  },
  total: {
    color: '#ff0000'
  },
  success: {
    marginTop: '20px',
    marginBottom: '60px',
    textAlign: 'center'
  },
  message: {
    fontWeight: 500
  }
}));

const WithdrawalDialog = ({
  customerName,
  handleClose,
  open,
  reset,
  setReset,
  walletBalance,
  walletId,
  withdrawalAmount,
  withdrawFromWallet
}: Props) => {
  const classes = useStyles();

  const [withdrawalLoading, setWithdrawalLoading] = useState(false);

  const validationSchema = yup.object().shape({
    amount: yup.number().required(`Please enter amount to top up`),
    purpose: yup.string().required(`Please enter purpose for top up`)
  });

  const shipmentValidationSchema = yup.object().shape({
    amount: yup.number().required(`Please enter amount to top up`)
  });

  const [updateBalance, { loading, error, data }]: any =
    useUpdateWalletBalanceMutation();

  const { errors, values, touched, handleSubmit, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: withdrawalAmount || '',
      purpose: ''
    },
    validationSchema: withdrawalAmount
      ? shipmentValidationSchema
      : validationSchema,
    onSubmit: async values => {
      const data = {
        walletId,
        amount: Number(values.amount) * 100,
        operationType: walletTransactionTypesEnum.DEBIT,
        purpose: values.purpose
      };

      setReset(false);
      setWithdrawalLoading(true);

      if (withdrawalAmount) {
        await withdrawFromWallet?.();
        setWithdrawalLoading(false);
      } else {
        setWithdrawalLoading(false);
        await updateBalance(data);
      }
    }
  });

  return (
    <div>
      <DialogShell
        actionButtonText='Debit'
        handleClose={handleClose}
        hideActionButton={!reset && data && !error}
        loading={loading || withdrawalLoading}
        open={open}
        title=''
        handleSubmit={handleSubmit}
      >
        <form className={classes.form}>
          {!reset && data && !error ? (
            <div className={classes.success}>
              <img
                width='40%'
                src='/static/images/svg/checkmark.svg'
                alt='checkmark'
              />
              <Typography className={classes.message}>
                Wallet debit was successful for {customerName}
              </Typography>
            </div>
          ) : (
            <>
              <div>
                <Typography className={classes.title}>
                  {' '}
                  Debit for customer
                </Typography>
                <Typography variant='subtitle1' color='primary'>
                  {customerName}
                </Typography>
              </div>

              <Divider variant='fullWidth' />

              <div className={classes.formContent}>
                <Typography variant='subtitle1'>Wallet Balance</Typography>
                <Typography variant='h5' color='primary'>
                  {toCurrency(walletBalance, 'NGN')}
                </Typography>
              </div>

              <FormControl className={classes.formContent} fullWidth>
                <InputLabel className='form-label' htmlFor='amount'>
                  Debit amount
                </InputLabel>

                <Input
                  id='amount'
                  type='string'
                  name='amount'
                  value={`${numberWithCommas(values.amount)}`}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { value, name } = event.target;
                    if (!(value && value.length)) {
                      handleChange({
                        target: { name, value: '' }
                      });
                    } else if (!isNaN(Number(value.split(',').join('')))) {
                      const itemValue = parseInt(value.split(',').join(''), 10);
                      handleChange({
                        target: { name, value: itemValue }
                      });
                    }
                  }}
                  error={Boolean(touched.amount && errors.amount)}
                  fullWidth
                  startAdornment={
                    <InputAdornment position='start'>
                      <AccountBalanceIcon color='primary' />
                    </InputAdornment>
                  }
                />

                <small>{touched.amount && errors.amount}</small>
              </FormControl>

              {!withdrawalAmount ? (
                <FormControl className={classes.formContent2} fullWidth>
                  <InputLabel className='form-label' htmlFor='purpose'>
                    Purpose
                  </InputLabel>

                  <Input
                    id='purpose'
                    type='text'
                    name='purpose'
                    value={values.purpose}
                    onChange={handleChange}
                    error={Boolean(touched.purpose && errors.purpose)}
                    fullWidth
                    startAdornment={
                      <InputAdornment position='start'>
                        <ChatIcon color='primary' />
                      </InputAdornment>
                    }
                  />

                  <small>{touched.purpose && errors.purpose}</small>
                </FormControl>
              ) : null}

              <Typography className={classes.content} variant='subtitle2'>
                Total
              </Typography>
              <Typography className={classes.total} variant='h6'>
                {toCurrency(walletBalance - Number(values.amount), 'NGN')}
              </Typography>
            </>
          )}
        </form>
      </DialogShell>
    </div>
  );
};

export default WithdrawalDialog;
