import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ChatIcon from '@material-ui/icons/Chat';
import { toCurrency } from 'helpers';
import { DialogShell } from 'components';
import { useUpdateWalletBalanceMutation } from 'operations/mutations';
import { walletTransactionTypesEnum } from 'enums';

type Props = {
  customerName: string;
  handleClose: () => void;
  open: boolean;
  reset: boolean;
  setReset: (reset: boolean) => void;
  bonusBalance: number;
  walletId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  title: {
    fontSize: '18px',
    fontWeight: 700
  },
  form: {
    padding: '0px 10px 10px 10px'
  },
  formContent: {
    marginTop: theme.spacing(4)
  },
  formContent2: {
    marginTop: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(6)
  },
  total: {
    color: '#43d882'
  },
  success: {
    marginTop: '20px',
    marginBottom: '60px',
    textAlign: 'center'
  },
  message: {
    fontWeight: 500
  }
}));

const AddBonusDialog = ({
  customerName,
  handleClose,
  open,
  reset,
  setReset,
  bonusBalance,
  walletId
}: Props) => {
  const classes = useStyles();
  const validationSchema = yup.object().shape({
    bonus: yup.number().required(`Please enter amount to top up`),
    purpose: yup.string().required(`Please enter purpose for top up`)
  });

  const [updateBalance, { loading, error, data }]: any =
    useUpdateWalletBalanceMutation();

  const { errors, values, touched, handleSubmit, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      bonus: '',
      purpose: ''
    },
    validationSchema,
    onSubmit: async values => {
      const data = {
        walletId,
        bonusAmount: Number(values.bonus) * 100,
        operationType: walletTransactionTypesEnum.CREDIT,
        purpose: values.purpose
      };

      setReset(false);
      await updateBalance(data);
    }
  });

  return (
    <div>
      <DialogShell
        actionButtonText='Add Bonus'
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        hideActionButton={!reset && data && !error}
        loading={loading}
        open={open}
        title=''
      >
        <form className={classes.form}>
          {!reset && data && !error ? (
            <div className={classes.success}>
              <img
                width='40%'
                src='/static/images/svg/checkmark.svg'
                alt='checkmark'
              />
              <Typography className={classes.message}>
                Bonus top up was successful for {customerName}
              </Typography>
            </div>
          ) : (
            <>
              <div>
                <Typography className={classes.title}>
                  {' '}
                  Bonus top up for customer
                </Typography>
                <Typography variant='subtitle1' color='primary'>
                  {customerName}
                </Typography>
              </div>

              <Divider variant='fullWidth' />

              <FormControl className={classes.formContent} fullWidth>
                <InputLabel className='form-label' htmlFor='bonus'>
                  Bonus Amount
                </InputLabel>

                <Input
                  id='bonus'
                  type='number'
                  name='bonus'
                  value={values.bonus}
                  onChange={handleChange}
                  error={Boolean(touched.bonus && errors.bonus)}
                  fullWidth
                  startAdornment={
                    <InputAdornment position='start'>
                      <AccountBalanceIcon color='primary' />
                    </InputAdornment>
                  }
                />

                <small>{touched.bonus && errors.bonus}</small>
              </FormControl>

              <FormControl className={classes.formContent2} fullWidth>
                <InputLabel className='form-label' htmlFor='purpose'>
                  Purpose
                </InputLabel>

                <Input
                  id='purpose'
                  type='text'
                  name='purpose'
                  value={values.purpose}
                  onChange={handleChange}
                  error={Boolean(touched.purpose && errors.purpose)}
                  fullWidth
                  startAdornment={
                    <InputAdornment position='start'>
                      <ChatIcon color='primary' />
                    </InputAdornment>
                  }
                />

                <small>{touched.purpose && errors.purpose}</small>
              </FormControl>

              <Typography className={classes.content} variant='subtitle2'>
                Total
              </Typography>
              <Typography className={classes.total} variant='h6'>
                {toCurrency(bonusBalance + Number(values.bonus), 'NGN')}
              </Typography>
            </>
          )}
        </form>
      </DialogShell>
    </div>
  );
};

export default AddBonusDialog;
