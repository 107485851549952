import gql from 'graphql-tag';

const LOGIN_USER = gql`
  mutation loginUser($existingUser: LoginInput!) {
    login(loginInput: $existingUser) {
      accessToken
      userType
    }
  }
`;

const SAVE_SHIPMENT = gql`
  mutation saveShipment($shipmentDetails: [AddShipmentInput!]!) {
    saveShipment(shipment: $shipmentDetails) {
      id
      trackingId
      isPaid
      shipmentStatus
      valueAddedTaxCharge
      user {
        id
        topshipId
        fullName
      }
      transaction {
        id
        finalBalance
      }
    }
  }
`;

const SAVE_CUSTOMER_SHIPMENT = gql`
  mutation saveCustomerShipment(
    $coupon: String
    $discount: Boolean
    $shipmentDetails: [AddShipmentInput!]!
    $customerInfo: String!
  ) {
    saveCustomerShipment(
      coupon: $coupon
      addDiscount: $discount
      details: $shipmentDetails
      customerPhoneNumberOrEmail: $customerInfo
    ) {
      id
      trackingId
      isPaid
      shipmentStatus
      totalCharge
      user {
        id
        topshipId
        fullName
      }
      transaction {
        id
        finalBalance
      }
    }
  }
`;

const SAVE_CUSTOMER_SHOPNSHIP = gql`
  mutation saveCustomerShopnship(
    $coupon: String
    $details: AddShopnshipInput!
    $customerInfo: String!
  ) {
    saveCustomerShopnship(
      coupon: $coupon
      details: $details
      customerPhoneNumberOrEmail: $customerInfo
    ) {
      id
      initialCharge
      email
      phoneNumber
      shippingFrom
      packageName
      trackingNumber
      deliveryName
      deliveryAddress
      estimatedWeight
      itemDescription
      amountDue
      receiverPaymentDetails
      uploadingMyDocuments
      proofOfId
      category
      evidenceOfPurchase
      couponApplied
      couponAppliedAmount
      insuranceCharge
      insuranceType
      user {
        id
        topshipId
        email
      }
    }
  }
`;

const SHOP_N_SHIP = gql`
  mutation shopNShip($coupon: String, $shopnship: AddShopnshipInput!) {
    saveShopnship(coupon: $coupon, shopnship: $shopnship) {
      id
      initialCharge
      email
      phoneNumber
      shippingFrom
      packageName
      trackingNumber
      deliveryName
      deliveryAddress
      estimatedWeight
      itemDescription
      amountDue
      receiverPaymentDetails
      uploadingMyDocuments
      proofOfId
      category
      evidenceOfPurchase
      couponApplied
      couponAppliedAmount
      insuranceCharge
      insuranceType
      user {
        id
        topshipId
        email
      }
    }
  }
`;

const PAY_FROM_WALLET = gql`
  mutation payFromWallet($detail: PayFromWalletInput) {
    payFromWallet(detail: $detail) {
      id
      isPaid
    }
  }
`;

const SAVE_ADDRESS = gql`
  mutation saveAddress($address: AddAddressInput!) {
    saveAddress(address: $address) {
      state
    }
  }
`;

const DELETE_ADDRESS = gql`
  mutation deleteAddress($id: String!) {
    deleteAddress(id: $id) {
      id
    }
  }
`;

const UPDATE_WALLET_BALANCE = gql`
  mutation updateWalletBalance($walletBalance: UpdateWalletBalanceInput) {
    updateWalletBalance(detail: $walletBalance) {
      id
      finalBalance
      initialBalance
      amount
      transactionReference
      finalBonusBalance
      initialBonusBalance
      bonusAmount
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($update: UserUpdate, $topshipId: String!) {
    updateUser(update: $update, topshipId: $topshipId) {
      id
      topshipId
      type
      email
      phoneNumber
      percentageCommissionOnUser
    }
  }
`;

const UPDATE_BUSINESS = gql`
  mutation updateBusinessInformation(
    $update: UpdateBusinessInformationInput!
    $businessId: String!
  ) {
    updateBusinessInformation(update: $update, businessId: $businessId) {
      isVerified
    }
  }
`;

const UPDATE_SHIPMENT = gql`
  mutation updateShipment(
    $update: UpdateShipmentInput!
    $id: String!
    $statusMessage: String
  ) {
    updateShipment(update: $update, id: $id, statusMessage: $statusMessage) {
      id
      trackingId
      label
      trackingUrl
      isPaid
      totalCharge
    }
  }
`;

const BOOK_YOU_PARCEL = gql`
  mutation bookYouParcel($shipmentId: String!) {
    bookYouParcel(shipmentId: $shipmentId) {
      id
      trackingId
      trackingUrl
      youparcelLabel
      youparcelInvoice
    }
  }
`;

const EDIT_SHIPMENT = gql`
  mutation editShipment(
    $coupon: String
    $discount: Boolean
    $newShipment: AddShipmentInput!
    $shipmentId: String!
  ) {
    editShipment(
      coupon: $coupon
      addDiscount: $discount
      newShipment: $newShipment
      shipmentId: $shipmentId
    ) {
      id
      trackingId
      isPaid
      shipmentStatus
      totalCharge
      user {
        id
        topshipId
        fullName
        email
        phoneNumber
        wallet {
          id
          totalReceived
          totalSent
          currency
        }
      }
      transaction {
        id
        finalBalance
      }
    }
  }
`;

const UPDATE_SHOPNSHIP = gql`
  mutation updateShopnship($update: UpdateShopnshipInput!, $id: String!) {
    updateShopnship(update: $update, id: $id) {
      id
      amountDue
      totalCharge
      status
    }
  }
`;

const CREATE_USER = gql`
  mutation createNewUser($userInfo: CreateTopshipUserInput!) {
    createTopshipUser(createUserInput: $userInfo) {
      id
      topshipId
      fullName
      email
      phoneNumber
    }
  }
`;

const CREATE_TOPSHIP_ADMIN = gql`
  mutation createNewTopshipAdmin($userInfo: CreateTopshipAdminInput!) {
    createTopshipAdmin(createAdminInput: $userInfo) {
      id
      topshipId
      fullName
      email
      phoneNumber
    }
  }
`;

const REPROCESS_MESSAGE = gql`
  mutation reprocessTransaction($id: String!) {
    reprocessMessage(id: $id) {
      id
      createdDate
      updatedDate
      paystackEvent
      error
      retriesCount
      status
    }
  }
`;

const UPDATE_MESSAGE = gql`
  mutation updateTransactionMessage(
    $messageInput: updateMessageInput
    $id: String!
  ) {
    updateMessage(update: $messageInput, id: $id) {
      id
      createdDate
      updatedDate
      paystackEvent
      error
      retriesCount
      status
    }
  }
`;

const DECLINE_WITHDRAWAL_REQUEST = gql`
  mutation declineRequest($id: String!) {
    declineWithdrawalRequest(id: $id) {
      id
      requestId
      user {
        id
        topshipId
        fullName
      }
      amount
      currency
    }
  }
`;

const APPROVE_WITHDRAWAL_REQUEST = gql`
  mutation approveRequest($id: String!) {
    approveWithdrawalRequest(id: $id) {
      status
      message
      transferCode
    }
  }
`;

const AUTHENTICATE_PAYSTACK_TRANSFER = gql`
  mutation authenticateRequest($otp: String!, $transferCode: String!) {
    authenticatePaystackTransfer(otp: $otp, transferCode: $transferCode) {
      status
      message
      transferCode
    }
  }
`;

const RESEND_FAILED_TRANSFER = gql`
  mutation resendTransfer($id: String!) {
    resendFailedTransfer(id: $id) {
      status
      message
      transferCode
    }
  }
`;

const UPDATE_CONFIG = gql`
  mutation updateConfig($configure: UpdateConfigurationInput) {
    updateConfiguration(config: $configure) {
      commissionPercent
      minimumWithdrawal
      referralBonus
      autoApproveWithdrawal
      dhlShippingDate
      discount
    }
  }
`;

const ADD_CITY = gql`
  mutation addToCities($city: AddCityInput!) {
    addCity(city: $city) {
      cityName
      suburbName
      postcode
    }
  }
`;

const ADD_STATE = gql`
  mutation addToStates($state: AddStateInput!) {
    addState(state: $state) {
      slug
      pk
      countryCode
      name
      code
    }
  }
`;

const ADD_COUNTRY = gql`
  mutation addToCountries($country: AddCountryInput!) {
    addCountry(country: $country) {
      code
      name
    }
  }
`;

const CREATE_COUPON = gql`
  mutation createCoupon($input: CreateCouponCodeInput!) {
    createCouponCode(createCouponCodeInput: $input) {
      id
      isActive
      value
      code
      limit
      type
    }
  }
`;

const CREATE_ADDITIONAL_CHARGE = gql`
  mutation createAdditionalCharge($input: CreateAdditionalChargeInput!) {
    createAdditionalCharge(input: $input) {
      id
    }
  }
`;

const UPDATE_TICKET = gql`
  mutation updateTicket($input: UpdateTicketInput!) {
    updateTicket(input: $input) {
      id
      status
    }
  }
`;

const CREATE_NOTE = gql`
  mutation createNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      id
      message
      createdDate
    }
  }
`;

const CREATE_TICKET = gql`
  mutation createTicket($input: CreateTicketInput!) {
    createTicket(input: $input) {
      id
    }
  }
`;

const UPLOAD_SHIPMENT_DOCUMENTS = gql`
  mutation uploadShipmentDocuments($input: UploadShipmentDocumentsInput!) {
    uploadShipmentDocuments(input: $input) {
      id
      documents {
        id
      }
    }
  }
`;

const BOOK_PICKUP = gql`
  mutation bookPickup($shipmentId: ID!) {
    bookPickup(shipmentId: $shipmentId) {
      id
      pickupTrackingUrl
    }
  }
`;

export {
  ADD_CITY,
  ADD_COUNTRY,
  ADD_STATE,
  APPROVE_WITHDRAWAL_REQUEST,
  AUTHENTICATE_PAYSTACK_TRANSFER,
  CREATE_COUPON,
  CREATE_USER,
  CREATE_TOPSHIP_ADMIN,
  DECLINE_WITHDRAWAL_REQUEST,
  DELETE_ADDRESS,
  LOGIN_USER,
  PAY_FROM_WALLET,
  REPROCESS_MESSAGE,
  RESEND_FAILED_TRANSFER,
  SAVE_ADDRESS,
  SAVE_SHIPMENT,
  SAVE_CUSTOMER_SHIPMENT,
  UPDATE_CONFIG,
  UPDATE_MESSAGE,
  UPDATE_SHIPMENT,
  UPDATE_USER,
  UPDATE_WALLET_BALANCE,
  SHOP_N_SHIP,
  UPDATE_SHOPNSHIP,
  SAVE_CUSTOMER_SHOPNSHIP,
  EDIT_SHIPMENT,
  UPDATE_BUSINESS,
  CREATE_ADDITIONAL_CHARGE,
  BOOK_YOU_PARCEL,
  UPDATE_TICKET,
  CREATE_NOTE,
  CREATE_TICKET,
  UPLOAD_SHIPMENT_DOCUMENTS,
  BOOK_PICKUP
};
