const shipmentTypes = [
  {
    name: 'Drop-off',
    summary: 'Drop-off your items at the nearest Topship processing center',
    cost: 'FREE',
    value: 'DropOff',
    amount: 0.0
  },
  {
    name: 'Request a Pick-up',
    summary:
      'A dispatch rider will pick up your items at your preferred location',
    cost: '',
    value: 'PickUp',
    amount: 500.0
  }
];

export { shipmentTypes };
