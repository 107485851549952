import React, { ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  SubmitButton
} from 'components';

type Props = {
  actionButtonText?: string;
  children: ReactNode;
  handleClose: () => void;
  handleSubmit?: () => void;
  hideActionButton?: boolean;
  loading?: boolean;
  open: boolean;
  title?: string;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
};

const DialogShell = ({
  actionButtonText,
  children,
  handleClose,
  handleSubmit,
  hideActionButton,
  loading,
  open,
  title,
  maxWidth = 'xs'
}: Props) => {
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        maxWidth={maxWidth}
        fullWidth
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {title}
        </DialogTitle>

        <DialogContent>{children}</DialogContent>

        {!hideActionButton ? (
          <DialogActions>
            <div>
              <SubmitButton
                disabled={loading}
                loading={loading}
                onClick={handleSubmit}
                text={actionButtonText}
                width='100%'
              />
            </div>
          </DialogActions>
        ) : null}
      </Dialog>
    </div>
  );
};

export default DialogShell;
