import React, { ButtonHTMLAttributes } from 'react';

type Props = {
  loading?: boolean;
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  width?: string;
  padding?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
};

const SubmitButton = ({
  loading,
  text,
  onClick,
  disabled,
  className,
  width,
  padding,
  type
}: Props) => {
  return (
    <button
      type={type || 'submit'}
      className={`submit-button ${disabled && 'disable-element'} ${className} ${
        loading && 'loading'
      }`}
      style={{
        padding: padding || '16px',
        width: width || '100%'
      }}
      disabled={loading || disabled}
      onClick={onClick}
    >
      <span>{text}</span>
    </button>
  );
};

export default SubmitButton;
