function testJSON(text: string) {
  if (typeof text !== 'string') {
    return false;
  }
  try {
    var json = JSON.parse(text);
    return typeof json === 'object';
  } catch (error) {
    return false;
  }
}

function testMultipleStrings(text: string) {
  if (typeof text !== 'string') {
    return false;
  }
  try {
    const multipleStrings = text.split(',');
    if (multipleStrings.length > 1) return true;
    else return false;
  } catch (error) {
    return false;
  }
}

export { testJSON, testMultipleStrings };
