import { atom, RecoilState } from 'recoil';
import moment from 'moment';
import implementPersist from 'recoil-data/usePersist';
import { IDocument } from 'types/shipments.d';
import { UserType } from 'types';

const loginState = atom({
  key: 'loginState',
  default: false
});

const shopNShipDetailsState = atom({
  key: 'shopNShipDetails',
  default: {},
  effects_UNSTABLE: implementPersist('shopNShipDetails')
}) as RecoilState<any>;

const shopNShipResultState = atom({
  key: 'shopNShipResultState',
  default: {}
});
const couponResultState = atom({
  key: 'couponResultState',
  default: {
    type: '',
    value: 0
  }
});
const shopnshipDebitAmountState = atom({
  key: 'shopnshipDebitAmountState',
  default: 0
});
const chinaImportState = atom({
  key: 'chinaImportState',
  default: false,
  effects_UNSTABLE: implementPersist('chinaImportState')
});
const shopNShipValidationState = atom({
  key: 'shopNShipValidationState',
  default: {
    isValidated: false,
    enforceValidation: false
  }
});
const shipmentRequestState = atom({
  key: 'shipmentRequestState',
  default: 0
});
const shopnshipRateState = atom({
  key: 'shopnshipRateState',
  default: {
    rate: 0,
    fee: 0,
    finalAmount: 0,
    currency: '',
    amount: ''
  },
  effects_UNSTABLE: implementPersist('shopnshipRateState')
});

const shipmentPaymentState = atom({
  key: 'shipmentPaymentState',
  default: 0
});

const multipleSaveShipmentLoadingState = atom({
  key: 'multipleSaveShipmentLoadingState',
  default: {
    loading: false,
    completed: false
  }
});

const quoteRequestState = atom({
  key: 'quoteRequestState',
  default: 0
});

const selectedRateState = atom({
  key: 'selectedRateState',
  default: {
    mode: '',
    cost: 0,
    pricingTier: ''
  }
});
const selectedLastMileState = atom({
  key: 'selectedLastMileState',
  default: {
    mode: '',
    cost: 0,
    pricingTier: ''
  }
});

const shipmentTypeState = atom({
  key: 'shipmentTypeState',
  default: {
    name: '',
    amount: 0
  }
});

const shipmentItemsState = atom({
  key: 'shipmentItemsState',
  default: [
    {
      category: '',
      weight: '',
      quantity: '',
      value: '',
      description: ''
    }
  ]
});

const selectedInsurancePlan = atom({
  key: 'selectedInsurancePlan',
  default: {
    name: '',
    amount: 0
  }
});

const selectedShippingMethod = atom({
  key: 'selectedShippingMethod',
  default: {}
});

const selectedPaymentMethod = atom({
  key: 'selectedPaymentMethod',
  default: {
    value: ''
  }
});

const selectedWalletFundingOption = atom({
  key: 'selectedWalletFundingOption',
  default: {}
});

const transactionReferenceState = atom({
  key: 'transactionReferenceState',
  default: null
});

const shipmentStatusState = atom({
  key: 'shipmentStatusState',
  default: ''
});

const saveShipmentDetailsState = atom({
  key: 'saveShipmentDetailsState',
  default: {
    itemCollectionMode: '',
    senderDetail: {
      name: '',
      email: '',
      phoneNumber: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      country: '',
      state: '',
      city: '',
      countryCode: '',
      postalCode: ''
    },
    receiverDetail: {
      name: '',
      email: '',
      phoneNumber: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      country: '',
      state: '',
      city: '',
      countryCode: '',
      postalCode: ''
    },
    items: [
      {
        category: '',
        weight: '',
        quantity: '',
        value: '',
        description: '',
        boxNumber: 1
      }
    ],
    pricingTier: '',
    insuranceType: '',
    insuranceCharge: 0,
    pickupCharge: 0,
    pickupDate: '',
    shipmentCharge: 0,
    valueAddedTaxCharge: 0,
    shipmentRoute: '',
    additionalInformation: '',
    multipleReceivers: [
      {
        receiverDetail: {
          name: '',
          email: '',
          phoneNumber: '',
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          country: '',
          state: '',
          city: '',
          countryCode: '',
          postalCode: ''
        },
        items: [
          {
            category: '',
            weight: '',
            quantity: '',
            value: '',
            description: '',
            boxNumber: 1
          }
        ],
        couponCode: '',
        pricingTier: '',
        insuranceType: '',
        shipmentCharge: 0,
        insuranceCharge: 0
      }
    ]
  },
  effects_UNSTABLE: implementPersist('saveShipmentDetailsState')
});

const saveShopnshipItemDetails = atom({
  key: 'saveShopnshipItemDetails',
  default: {
    items: [
      {
        trackingNumber: '',
        amountDue: 0,
        estimatedWeight: 0,
        category: '',
        quantity: '',
        additionalInformation: ''
      }
    ]
  }
});

const saveUserInformationState = atom({
  key: 'saveUserInformationState',
  default: {
    topshipId: '',
    id: '',
    fullName: '',
    phoneNumber: '',
    email: '',
    accountType: '',
    businessInformation: {
      isVerified: false,
      directors: []
    },
    wallet: {
      id: '',
      totalReceived: 0,
      totalSent: 0
    }
  },
  effects_UNSTABLE: implementPersist('saveUserInformationState')
});

const saveSelectedCustomerEmailState = atom({
  key: 'saveSelectedCustomerEmailState',
  default: ''
});

const saveShipmentRateState = atom({
  key: 'saveShipmentRate',
  default: [
    {
      mode: '',
      cost: 0,
      duration: '',
      pricingTier: ''
    }
  ]
});

const saveLastMileRateState = atom({
  key: 'saveLastMileRateState',
  default: [
    {
      mode: '',
      cost: 0,
      duration: '',
      pricingTier: '',
      logo: ''
    }
  ]
});

const saveSenderAddress = atom({
  key: 'saveSenderAddress',
  default: {
    location: '',
    country: '',
    state: '',
    city: '',
    countryCode: ''
  }
});

const saveReceiverAddress = atom({
  key: 'saveReceiverAddress',
  default: {
    location: '',
    country: '',
    state: '',
    city: '',
    countryCode: ''
  }
});

const shipmentRateInformationState = atom({
  key: 'saveShipmentRateInformation',
  default: {}
});

const walletState = atom({
  key: 'walletState',
  default: {
    walletId: '',
    walletBalance: 0,
    bonusBalance: 0
  },
  effects_UNSTABLE: implementPersist('walletState')
});

const userNumberState = atom({
  key: 'userNumberState',
  default: 0
});
const businessNumberState = atom({
  key: 'businessNumberState',
  default: 0
});
const shipmentNumberState = atom({
  key: 'shipmentNumberState',
  default: 0
});

const shopNShipNumberState = atom({
  key: 'shopNShipNumberState',
  default: 0
});

const feedbackNumberState = atom({
  key: 'feedbackNumberState',
  default: 0
});

const transactionsNumberState = atom({
  key: 'transactionsNumberState',
  default: 0
});

const deadlettersNumberState = atom({
  key: 'deadlettersNumberState',
  default: 0
});

const withdrawalRequestNumberState = atom({
  key: 'withdrawalRequestNumberState',
  default: 0
});

const busyOverlayState = atom({
  key: 'busyOverlayState',
  default: false
});

const senderAddress = atom({
  key: 'senderAddress',
  default: {}
});

const receiverAddress = atom({
  key: 'receiverAddress',
  default: {}
});

const quotesSenderAddress = atom({
  key: 'quotesSenderAddress',
  default: {}
});

const quotesReceiverAddress = atom({
  key: 'quotesReceiverAddress',
  default: {}
});

const quotesWeight = atom({
  key: 'quotesWeight',
  default: null
});

const saveSenderAddressState = atom({
  key: 'saveSenderAddressState',
  default: false
});

const saveReceiverAddressState = atom({
  key: 'saveReceiverAddressState',
  default: false
});

const shipmentSenderAddressVerified = atom({
  key: 'shipmentSenderAddressVerified',
  default: false
});

const warningState = atom({
  key: 'warningState',
  default: false
});

const saveConfigurationState = atom({
  key: 'saveConfigurationState',
  default: {
    commissionPercent: 0,
    paystackCommissionPercent: 0,
    minimumWithdrawal: 0,
    autoApproveWithdrawal: false,
    dhlShippingDate: moment().format('yyyy-MM-DDThh:mm'),
    referralBonus: 0,
    discount: 0
  }
});

const multipleShipmentRatesState = atom<{ index?: number; list?: any[] }[]>({
  key: 'multipleShipmentRatesState',
  default: []
});

const multipleRateLoadingState = atom({
  key: 'multipleRateLoadingState',
  default: false
});

const multipleShipmentResultsState = atom<{ index?: number; data?: any }[]>({
  key: 'multipleShipmentResultsState',
  default: []
});

const ticketNumberState = atom({
  key: 'ticketNumberState',
  default: 0
});

const ticketUserState = atom<{
  email: string;
  name: string;
  phoneNumber: string;
}>({
  key: 'ticketUserState',
  default: {
    email: '',
    name: '',
    phoneNumber: ''
  },
  effects_UNSTABLE: implementPersist('ticketUserState')
});

const shipmentDocumentsState = atom<{
  documents: IDocument[];
  user: Partial<UserType>;
}>({
  key: 'shipmentDocumentsState',
  default: {
    documents: [],
    user: {
      email: '',
      fullName: '',
      phoneNumber: ''
    }
  },
  effects_UNSTABLE: implementPersist('shipmentDocumentsState')
});

export {
  loginState,
  feedbackNumberState,
  shipmentRequestState,
  shipmentPaymentState,
  quoteRequestState,
  selectedRateState,
  shipmentTypeState,
  shipmentItemsState,
  selectedInsurancePlan,
  selectedShippingMethod,
  selectedPaymentMethod,
  selectedWalletFundingOption,
  transactionReferenceState,
  saveShipmentDetailsState,
  walletState,
  shopnshipDebitAmountState,
  saveShipmentRateState,
  shipmentRateInformationState,
  busyOverlayState,
  senderAddress,
  receiverAddress,
  quotesReceiverAddress,
  quotesSenderAddress,
  quotesWeight,
  saveSenderAddressState,
  saveReceiverAddressState,
  shipmentSenderAddressVerified,
  shopNShipNumberState,
  warningState,
  userNumberState,
  shipmentNumberState,
  transactionsNumberState,
  deadlettersNumberState,
  saveConfigurationState,
  saveSelectedCustomerEmailState,
  shopNShipResultState,
  shipmentStatusState,
  saveShopnshipItemDetails,
  saveSenderAddress,
  saveReceiverAddress,
  saveUserInformationState,
  multipleSaveShipmentLoadingState,
  withdrawalRequestNumberState,
  multipleShipmentRatesState,
  multipleRateLoadingState,
  multipleShipmentResultsState,
  shopNShipDetailsState,
  shopNShipValidationState,
  shopnshipRateState,
  couponResultState,
  businessNumberState,
  chinaImportState,
  ticketNumberState,
  ticketUserState,
  saveLastMileRateState,
  selectedLastMileState,
  shipmentDocumentsState
};
