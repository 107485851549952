import { useResetRecoilState } from 'recoil';
import {
  couponResultState,
  saveShipmentDetailsState,
  saveShipmentRateState,
  shopNShipDetailsState,
  multipleShipmentRatesState,
  chinaImportState
} from '../recoil-data/atoms';

export default function useClearLocalStorage() {
  const resetShopnshipDetailsState = useResetRecoilState(shopNShipDetailsState);
  const resetCouponState = useResetRecoilState(couponResultState);
  const resetImportState = useResetRecoilState(saveShipmentDetailsState);
  const resetChinaImportState = useResetRecoilState(chinaImportState);
  const resetShipmentRateState = useResetRecoilState(saveShipmentRateState);
  const resetMultipleShipmentRateState = useResetRecoilState(
    multipleShipmentRatesState
  );

  return (extra?: any) => {
    resetShopnshipDetailsState();
    resetCouponState();
    resetImportState();
    resetShipmentRateState();
    resetMultipleShipmentRateState();
    resetChinaImportState();
    extra && extra();
  };
}
