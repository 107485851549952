import { insurancePlans } from 'constant/insurance-plans.constant';
import {
  lastMileShippingRates,
  shippingRates
} from 'constant/shipment-rates.constant';

const getPlan = (type: 'insurance' | 'shipping' | 'lastMile', value: any) => {
  const plans = {
    shipping: shippingRates,
    insurance: insurancePlans,
    lastMile: lastMileShippingRates
  };

  if (!plans.hasOwnProperty(type)) return {};

  return (plans[type] as any[]).filter((plan: any) => plan.value === value)[0];
};

export default getPlan;
