export const shipmentOptions = [
  {
    id: 1,
    title: 'Book a Delivery',
    description: 'Send out a parcel, locally or internationally',
    value: 'Export'
  },
  {
    id: 2,
    title: 'Book an Import',
    description: 'Receive a package from anywhere in the world',
    value: 'Import'
  },
  {
    id: 3,
    title: 'Shop & ship',
    description: 'Shop & ship to our US or UK addresses',
    value: 'ShopNShip'
  }
];
