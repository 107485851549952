export const applyCouponToPrice = (
  shipmentAmount: number,
  couponValue: number,
  couponType: string
) => {
  if (couponType === 'Percentage') {
    return shipmentAmount - shipmentAmount * (couponValue / 100);
  } else {
    return couponValue > shipmentAmount ? 0 : shipmentAmount - couponValue;
  }
};
