import axios from 'axios';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { parseGraphqlError } from 'helpers';

const getDhlCities = async (params: {
  countryCode: string;
  cityName: string;
}) => {
  const { countryCode, cityName } = params;
  return await axios.get(
    `${process.env.REACT_APP_DHL_API}?city=${cityName || 'a'}&countryCode=${
      countryCode || 'NG'
    }`
  );
};

const getShopnshipCurrencyRate = async ({ shippingFrom, weight }: any) => {
  const from = shippingFrom === 'UK' ? 'GBP' : 'USD';

  const amount = 20;

  return axios.get(
    `${process.env.REACT_APP_REST_API}/rate-converter?from=${from}&to=NGN&amount=${amount}`
  );
};

const getDHLCurrentTracking = async (dhlTrackingNumber: string) => {
  try {
    const response = await axios.get(
      `https://mydhl.express.dhl/shipmentTracking?AWB=${dhlTrackingNumber}&countryCode=ng&languageCode=en`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': '*/*'
        }
      }
    );

    return response.data.results[0];
  } catch (error) {
    console.error(error);
    NotificationManager.error(parseGraphqlError(error), 'Error');
  }
};

export { getShopnshipCurrencyRate, getDhlCities, getDHLCurrentTracking };
