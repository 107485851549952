import React from 'react';
import { useHistory } from 'react-router-dom';
import successImage from 'assets/svg/black-friday.svg';
import { DialogShell } from 'components';
import { SubmitButton } from 'components';

type SuccessDialog = {
  open: boolean;
  handleClose: () => void;
};
const ShopnshipSuccessDialog = ({ open, handleClose }: SuccessDialog) => {
  const history = useHistory();

  return (
    <div>
      <DialogShell
        handleClose={handleClose}
        hideActionButton={true}
        open={open}
        title='Shop & Ship Successful'
      >
        <div className='flex flex-justify-center flex-align-center'>
          <img src={successImage} alt='success' />
          <div
            className=' mb-3 mt-3'
            style={{ width: '100%', marginTop: '10px' }}
          >
            <SubmitButton
              text='Go Home'
              className='mt-3'
              disabled={false}
              loading={false}
              onClick={() => {
                history.push('/dashboard');
              }}
            />
          </div>
        </div>
      </DialogShell>
    </div>
  );
};

export default ShopnshipSuccessDialog;
