import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import OtpInput from 'react-otp-input';
import { DialogShell } from 'components';

type Props = {
  handleChange: (otp: string) => void;
  handleClose: () => void;
  handleSubmit: () => void;
  hasErrored: boolean;
  inputStyle?: Object;
  isInputNum?: boolean;
  loading: boolean;
  numberOfDigits: number;
  open: boolean;
  otpValue: string;
  placeHolder?: string;
  separator?: DetailedHTMLProps<
    HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >;
  shouldAutoFocus?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  title: {
    fontSize: '18px',
    fontWeight: 700
  },
  form: {
    padding: '0px 10px 10px 10px'
  },
  formContent: {
    marginTop: theme.spacing(4)
  },
  formContent2: {
    marginTop: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(6)
  },
  total: {
    color: '#43d882'
  },
  success: {
    marginTop: '20px',
    marginBottom: '60px',
    textAlign: 'center'
  },
  message: {
    fontWeight: 500
  }
}));

const OTPDialog = ({
  handleChange,
  handleSubmit,
  handleClose,
  hasErrored,
  inputStyle,
  isInputNum,
  loading,
  numberOfDigits,
  open,
  otpValue,
  placeHolder,
  separator,
  shouldAutoFocus
}: Props) => {
  const classes = useStyles();

  return (
    <div>
      <DialogShell
        actionButtonText='Submit'
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        loading={loading}
        open={open}
        title='OTP Validation Required'
      >
        <form className={classes.form}>
          <OtpInput
            hasErrored={hasErrored}
            onChange={handleChange}
            numInputs={numberOfDigits}
            placeholder={placeHolder}
            separator={separator}
            shouldAutoFocus={shouldAutoFocus}
            value={otpValue}
            containerStyle={{ justifyContent: 'center' }}
            inputStyle={inputStyle}
            isInputNum={isInputNum}
            errorStyle={{ border: '1px solid #FF0000' }}
          />
        </form>
      </DialogShell>
    </div>
  );
};

export default OTPDialog;
