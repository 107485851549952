import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { toCurrency } from 'helpers';
import { DialogShell } from 'components';
import { usePayFromWalletMutation } from 'operations/mutations';
import { useRecoilState } from 'recoil';
import { useGetOneShopnshipQuery } from 'operations/queries';
import {
  couponResultState,
  shopnshipDebitAmountState,
  shopNShipDetailsState,
  shopNShipResultState
} from 'recoil-data/atoms';

type Props = {
  customerName: string;
  open: boolean;
  reset: boolean;
  setReset: (reset: boolean) => void;
  walletBalance: number;
  walletId: string;
  paymentID: string;
  withdrawalAmount?: number;
  setOpenSuccess?: any;
  isPendingShopnship?: boolean;
  handleClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  title: {
    fontSize: '18px',
    fontWeight: 700
  },
  form: {
    padding: '0px 10px 10px 10px'
  },
  formContent: {
    marginTop: theme.spacing(4)
  },
  formContent2: {
    marginTop: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(6)
  },
  total: {
    color: '#ff0000'
  },
  success: {
    marginTop: '20px',
    marginBottom: '60px',
    textAlign: 'center'
  },
  message: {
    fontWeight: 500
  }
}));

const ShopnshipPaymentDialog = ({
  customerName,
  open,
  setReset,
  walletBalance,
  setOpenSuccess,
  paymentID,
  handleClose,
  isPendingShopnship = false
}: Props) => {
  const classes = useStyles();
  const payFromWalletById = usePayFromWalletMutation();
  const [shopNShipResult, setShopNShipResult] = useRecoilState(
    shopNShipResultState
  ) as any;
  const [couponData] = useRecoilState(couponResultState) as any;
  const [shopnship] = useRecoilState(shopNShipDetailsState);
  const [debitAmount, setDebitAmount] = useRecoilState(
    shopnshipDebitAmountState
  );

  const [withdrawalLoading, setWithdrawalLoading] = useState(false);
  const [getShopnship] = useGetOneShopnshipQuery((data: any) => {
    setShopNShipResult(data?.getShopnship);
  }) as any;

  const handlePayment = async () => {
    setReset(false);
    setWithdrawalLoading(true);

    if (debitAmount) {
      const result = await payFromWalletById(paymentID);
      if (result) {
        if (isPendingShopnship) {
          window.location.reload();
        } else {
          setOpenSuccess(true);
        }
        setWithdrawalLoading(false);
      } else {
        setWithdrawalLoading(false);
      }
    } else {
      setWithdrawalLoading(false);
    }
  };

  useEffect(() => {
    if (Object.values(shopNShipResult).length > 0) {
      setDebitAmount(shopNShipResult.totalCharge / 100);
    }
    // eslint-disable-next-line
  }, [shopNShipResult]);

  useEffect(() => {
    if (open && Object.values(shopnship).length) {
      getShopnship(paymentID?.split('/')[0]);
    }
    // eslint-disable-next-line
  }, [paymentID, shopNShipResult, isPendingShopnship, couponData]);

  return (
    <div>
      <DialogShell
        actionButtonText={`Debit ${toCurrency(Math.round(debitAmount), 'NGN')}`}
        handleClose={() => {
          handleClose();
        }}
        hideActionButton={false}
        loading={withdrawalLoading}
        open={open}
        handleSubmit={handlePayment}
      >
        <form className={classes.form}>
          <div>
            <Typography className={classes.title}> Debit Customer</Typography>
            <Typography variant='subtitle1' color='primary'>
              {customerName}
            </Typography>
          </div>

          <Divider variant='fullWidth' />

          <div className={classes.formContent}>
            <Typography variant='subtitle1'>Wallet Balance:</Typography>
            <Typography variant='h5' color='primary'>
              {toCurrency(walletBalance, 'NGN')}
            </Typography>
          </div>
        </form>
      </DialogShell>
    </div>
  );
};

export default ShopnshipPaymentDialog;
